<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Parceltrack Management</h3>
                        <small>Create, Edit and manage parceltrack permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="updateProductCategory">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('title') }">
                                        <label for="title">หัวข้อ <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.title" type="text" class="form-control" id="title" name="title" aria-describedby="length-name" @click="hideError('title')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ (max - form.title.length) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('title')">
                                            <span class="help-block">
                                                <has-error :form="form" field="title"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"
                                        :class="{ 'has-error': form.errors.has('sort') }">
                                        <label for="sort">ลำดับ</label>
                                        <div class="input-group">
                                            <input v-model="form.sort" type="number" class="form-control" id="sort" name="sort" @click="hideError('sort')">
                                        </div>
                                        <div v-if="form.errors.has('sort')">
                                            <span class="help-block">
                                                <has-error :form="form" field="sort"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                    <router-link :to="{ name: 'parceltrack' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataId: null,
                form: new Form({
                    id: '',
                    title: '',
                    sort: '',
                }),
                module: 'parceltrack',
                max: 255
            }
        },
        mounted() {
            if (this.$gate.isAdmin()) {
                let app = this;
                let id = app.$route.params.id;
                axios.get('/api/parceltrack/' + id)
                    .then(function(resp) {
                        app.form.fill(resp.data.dataProductCategory);
                    })
                    .catch(function() {
                        app.$router.push({
                            path: '/SystemControl/ProductCategory'
                        });
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            loadData() {
                if (this.$gate.isAdmin()) {
                    let app = this;
                    axios.get('/api/parceltrack/' + app.form.id)
                        .then(function(resp) {
                            app.form.fill(resp.data.dataProductCategory);
                        })
                        .catch(function() {
                            app.$router.push({
                                path: '/SystemControl/ProductCategory'
                            });
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            updateProductCategory() {
                var app = this;
                this.form.post('/api/parceltrack/' + app.form.id)
                    .then(() => {
                        this.loadData();
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        }
    }
</script>
