<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>User Management</h3>
                        <small>Create, Edit and manage users permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="createUsers">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('name') }">
                                        <label for="name">ชื่อ <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.name" type="text" class="form-control" id="name" name="name" aria-describedby="length-name" @click="hideError('name')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ max - form . name . length }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('name')">
                                            <span class="help-block">
                                                <has-error :form="form" field="name"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('last_name') }">
                                        <label for="last_name">นามสกุล <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.last_name" type="text" class="form-control" id="last_name" name="last_name" aria-describedby="length-last-name" @click="hideError('last_name')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-last-name">{{ max - form . last_name . length }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('last_name')">
                                            <span class="help-block">
                                                <has-error :form="form" field="last_name"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="form.image">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <img class="img-thumbnail" :src="getImage()" alt="Image" style="width: 120px; height: 120px;">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="image">ไฟล์รูปภาพ <small class="text-green">ไฟล์รูปภาพนามสกุล JPG / JPEG / GIF / PNG เท่านั้น* (แนะนำ กว้าง 120 px, สูง 120 px)</small></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" id="image" name="image" ref="fileInputImage" class="form-control" @change="uploadImage">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('telephone') }">
                                        <label for="telephone">เบอร์โทรศัพท์</label>
                                        <div class="input-group">
                                            <input v-model="form.telephone" type="text" class="form-control" id="telephone" name="telephone" @click="hideError('telephone')">
                                        </div>
                                        <div v-if="form.errors.has('telephone')">
                                            <span class="help-block">
                                                <has-error :form="form" field="telephone"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('email') }">
                                        <label for="email">อีเมล์ (ใช้ในการเข้าระบบ)</label>
                                        <div class="input-group">
                                            <input v-model="form.email" type="text" class="form-control" id="email" name="email" @click="hideError('email')">
                                        </div>
                                        <div v-if="form.errors.has('email')">
                                            <span class="help-block">
                                                <has-error :form="form" field="email"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('password') }">
                                        <label for="password">รหัสผ่าน</label>
                                        <div class="input-group">
                                            <input v-model="form.password" type="password" class="form-control" id="password" name="password" @click="hideError('password')">
                                        </div>
                                        <div v-if="form.errors.has('password')">
                                            <span class="help-block">
                                                <has-error :form="form" field="password"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('password_confirmation') }">
                                        <label for="password_confirmation">ยืนยันรหัสผ่าน</label>
                                        <div class="input-group">
                                            <input v-model="form.password_confirmation" type="password" class="form-control" id="password_confirmation" name="password_confirmation" @click="hideError('password_confirmation')">
                                        </div>
                                        <div v-if="form.errors.has('password_confirmation')">
                                            <span class="help-block">
                                                <has-error :form="form" field="password_confirmation"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('sort') }">
                                        <label for="sort">ลำดับ</label>
                                        <div class="input-group">
                                            <input v-model="form.sort" type="number" class="form-control" id="sort" name="sort" @click="hideError('sort')">
                                        </div>
                                        <div v-if="form.errors.has('sort')">
                                            <span class="help-block">
                                                <has-error :form="form" field="sort"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                    <router-link :to="{ name: 'users' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    name: '',
                    last_name: '',
                    image: '',
                    telephone: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    role: '',
                    sort: '',
                }),
                max: 255
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            getSortUsers: function() {
                axios.get('/api/getSortUsers',{
                    params: {
                        
                    }
                }).then(function(response){
                    if(response.data == 1) {
                        this.form.sort = 1;
                    } else {
                        this.form.sort = response.data.sort + 1;
                    }
                }.bind(this));
            },
            getImage(){
                if(this.form.image) {
                    let imageImage = this.form.image;
                    return imageImage;
                } else {
                    return null;
                }
            },
            uploadImage(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if((file['type']!="image/jpg" && file['type']!="image/jpeg" && file['type']!="image/png" && file['type']!="image/gif" && file['type']!="image/webp" && file['type']!="image/j2k" && file['type']!="image/jp2" && file['type']!="image/jxr")){
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputImage = this.$refs.fileInputImage;
                    inputImage.type = 'text';
                    inputImage.type = 'file';

                    this.form.image = "";
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                };
                reader.readAsDataURL(file);
            },
            createUsers() {
                var app = this;
                this.form.post('/api/users')
                    .then(() => {
                        app.$router.push({
                            path: '/SystemControl/Users'
                        });
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        created: function() {
            this.getSortUsers();
        }
    }
</script>
