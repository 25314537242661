import { createRouter, createWebHistory } from "vue-router"

import Cart from "../components/frontEnd/cart/IndexComponent.vue";

import Checkout from "../components/frontEnd/checkout/IndexComponent.vue";

import Dashboard from "../components/backEnd/dashboard/Dashboard.vue";

import Users from "../components/backEnd/users/TableUsers.vue";
import CreateUsers from "../components/backEnd/users/CreateUsers.vue";
import EditedUsers from "../components/backEnd/users/EditedUsers.vue";

import Setting from "../components/backEnd/setting/Setting.vue";

import Slide from "../components/backEnd/slide/TableSlide.vue";
import CreateSlide from "../components/backEnd/slide/CreateSlide.vue";
import EditedSlide from "../components/backEnd/slide/EditedSlide.vue";

import ProductCategory from "../components/backEnd/product/category/TableProductCategory.vue";
import CreateProductCategory from "../components/backEnd/product/category/CreateProductCategory.vue";
import EditedProductCategory from "../components/backEnd/product/category/EditedProductCategory.vue";

import Product from "../components/backEnd/product/TableProduct.vue";
import CreateProduct from "../components/backEnd/product/CreateProduct.vue";
import EditedProduct from "../components/backEnd/product/EditedProduct.vue";

import Paystatus from "../components/backEnd/order/paystatus/TablePaystatus.vue";
import CreatePaystatus from "../components/backEnd/order/paystatus/CreatePaystatus.vue";
import EditedPaystatus from "../components/backEnd/order/paystatus/EditedPaystatus.vue";

import Order from "../components/backEnd/order/TableOrder.vue";
import EditedOrder from "../components/backEnd/order/EditedOrder.vue";

import Shipstatus from "../components/backEnd/order/shipstatus/TableShipstatus.vue";
import CreateShipstatus from "../components/backEnd/order/shipstatus/CreateShipstatus.vue";
import EditedShipstatus from "../components/backEnd/order/shipstatus/EditedShipstatus.vue";

import Parceltrack from "../components/backEnd/order/parceltrack/TableParceltrack.vue";
import CreateParceltrack from "../components/backEnd/order/parceltrack/CreateParceltrack.vue";
import EditedParceltrack from "../components/backEnd/order/parceltrack/EditedParceltrack.vue";

import Coupon from "../components/backEnd/coupon/TableCoupon.vue";
import CreateCoupon from "../components/backEnd/coupon/CreateCoupon.vue";
import EditedCoupon from "../components/backEnd/coupon/EditedCoupon.vue";

import Contact from "../components/backEnd/contact/TableContact.vue";
import CreateContact from "../components/backEnd/contact/CreateContact.vue";
import EditedContact from "../components/backEnd/contact/EditedContact.vue";

const routeInfos = [
    {
        path : "/cart",
        name : "cart",
        component : Cart
    }, {
        path : "/checkout",
        name : "checkout",
        component : Checkout
    }, {
        path : "/SystemControl",
        name : "index",
        component : Setting
    }, {
        path : "/SystemControl/Dashboard",
        name : "dashboard",
        component : Dashboard
    }, {
        path : "/SystemControl/Users",
        name : "users",
        component : Users
    }, {
        path : "/SystemControl/Users/created",
        name : "createdUsers",
        component : CreateUsers
    }, {
        path : "/SystemControl/Users/edited/:id",
        name : "editedUsers",
        component : EditedUsers
    }, {
        path : "/SystemControl/Setting",
        name : "setting",
        component : Setting
    }, {
        path : "/SystemControl/Slide",
        name : "slide",
        component : Slide
    }, {
        path : "/SystemControl/Slide/created",
        name : "createdSlide",
        component : CreateSlide
    }, {
        path : "/SystemControl/Slide/edited/:id",
        name : "editedSlide",
        component : EditedSlide
    }, {
        path : "/SystemControl/ProductCategory",
        name : "productCategory",
        component : ProductCategory
    }, {
        path : "/SystemControl/ProductCategory/created",
        name : "createdProductCategory",
        component : CreateProductCategory
    }, {
        path : "/SystemControl/ProductCategory/edited/:id",
        name : "editedProductCategory",
        component : EditedProductCategory
    }, {
        path : "/SystemControl/Product",
        name : "product",
        component : Product
    }, {
        path : "/SystemControl/Product/created",
        name : "createdProduct",
        component : CreateProduct
    }, {
        path : "/SystemControl/Product/edited/:id",
        name : "editedProduct",
        component : EditedProduct
    }, {
        path : "/SystemControl/Order",
        name : "order",
        component : Order
    }, {
        path : "/SystemControl/Order/edited/:id",
        name : "editedOrder",
        component : EditedOrder
    }, {
        path : "/SystemControl/Paystatus",
        name : "paystatus",
        component : Paystatus
    }, {
        path : "/SystemControl/Paystatus/created",
        name : "createdPaystatus",
        component : CreatePaystatus
    }, {
        path : "/SystemControl/Paystatus/edited/:id",
        name : "editedPaystatus",
        component : EditedPaystatus
    }, {
        path : "/SystemControl/Shipstatus",
        name : "shipstatus",
        component : Shipstatus
    }, {
        path : "/SystemControl/Shipstatus/created",
        name : "createdShipstatus",
        component : CreateShipstatus
    }, {
        path : "/SystemControl/Shipstatus/edited/:id",
        name : "editedShipstatus",
        component : EditedShipstatus
    }, {
        path : "/SystemControl/Parceltrack",
        name : "parceltrack",
        component : Parceltrack
    }, {
        path : "/SystemControl/Parceltrack/created",
        name : "createdParceltrack",
        component : CreateParceltrack
    }, {
        path : "/SystemControl/Parceltrack/edited/:id",
        name : "editedParceltrack",
        component : EditedParceltrack
    }, {
        path : "/SystemControl/Coupon",
        name : "coupon",
        component : Coupon
    }, {
        path : "/SystemControl/Coupon/created",
        name : "createdCoupon",
        component : CreateCoupon
    }, {
        path : "/SystemControl/Coupon/edited/:id",
        name : "editedCoupon",
        component : EditedCoupon
    }, {
        path : "/SystemControl/Contact",
        name : "contact",
        component : Contact
    }, {
        path : "/SystemControl/Contact/created",
        name : "createdContact",
        component : CreateContact
    }, {
        path : "/SystemControl/Contact/edited/:id",
        name : "editedContact",
        component : EditedContact
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes : routeInfos
});
    
export default router;