<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Slide Management</h3>
                        <small>Create, Edit and manage slide permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="updateSlide">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('title') }">
                                        <label for="title">หัวข้อ <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.title" type="text" class="form-control" id="title" name="title" aria-describedby="length-name" @click="hideError('title')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ (max - form.title.length) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('title')">
                                            <span class="help-block">
                                                <has-error :form="form" field="title"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="detail">รายละเอียด</label>
                                        <textarea v-model="form.detail" class="form-control" rows="5" id="detail" name="detail"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="form.image">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <img class="img-thumbnail" :src="getImage()" alt="Image" style="width: 450px;">
                                        <p v-if="form.image.length < 200"><a style="cursor: pointer; color: #fff; width: 150px;" class="btn btn-danger btn-sm mt-1" @click="deletedImage(form.id)"><i class="fa fa-trash"></i> ลบไฟล์รูปภาพ</a></p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="image">ไฟล์รูปภาพ <small class="text-green">ไฟล์รูปภาพนามสกุล JPG / JPEG / GIF / PNG เท่านั้น* (แนะนำ กว้าง 1920 px, สูง 750 px)</small></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" id="image" name="image" ref="fileInputImage" class="form-control" @change="uploadImage">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="link">ลิงก์</label>
                                        <input v-model="form.link" type="text" class="form-control" id="link" name="link">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="fancy-radio">
                                        <label><input v-model="form.linkTarget" name="linkTarget" id="linkTargetSelf" value="_self" type="radio"><span><i></i>ลิงก์ไปหากับหน้าต่าง Web Browser เดิม (_self)</span></label>
                                    </div>
                                    <div class="fancy-radio">
                                        <label><input v-model="form.linkTarget" name="linkTarget" id="linkTargetBlank" value="_blank" type="radio"><span><i></i>ลิงก์ไปหากับหน้าต่าง Web Browser ใหม่ทุกครั้ง (_blank)</span></label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"
                                        :class="{ 'has-error': form.errors.has('sort') }">
                                        <label for="sort">ลำดับ</label>
                                        <div class="input-group">
                                            <input v-model="form.sort" type="number" class="form-control" id="sort" name="sort" @click="hideError('sort')">
                                        </div>
                                        <div v-if="form.errors.has('sort')">
                                            <span class="help-block">
                                                <has-error :form="form" field="sort"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                    <router-link :to="{ name: 'slide' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataId: null,
                form: new Form({
                    id: '',
                    title: '',
                    detail: '',
                    image: '',
                    link: '',
                    linkTarget: '_self',
                    sort: '',
                }),
                module: 'slide',
                max: 255
            }
        },
        mounted() {
            if (this.$gate.isAdmin()) {
                let app = this;
                let id = app.$route.params.id;
                axios.get('/api/slide/' + id)
                    .then(function(resp) {
                        app.form.fill(resp.data.dataSlide);
                        if(app.form.linkTarget === null || app.form.linkTarget === "") {
                            app.form.linkTarget = '_self';
                        }
                    })
                    .catch(function() {
                        app.$router.push({
                            path: '/SystemControl/Slide'
                        });
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            loadData() {
                if (this.$gate.isAdmin()) {
                    let app = this;
                    axios.get('/api/slide/' + app.form.id)
                        .then(function(resp) {
                            app.form.fill(resp.data.dataSlide);
                            if(app.form.linkTarget === null || app.form.linkTarget === "") {
                                app.form.linkTarget = '_self';
                            }
                        })
                        .catch(function() {
                            app.$router.push({
                                path: '/SystemControl/Slide'
                            });
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            getImage() {
                if (this.form.image) {
                    let imageImage = (this.form.image.length > 200) ? this.form.image : "/upload/slide/image/" + this.form.image;
                    return imageImage;
                } else {
                    return null;
                }
            },
            uploadImage(e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if ((file['type'] != "image/jpg" && file['type'] != "image/jpeg" && file['type'] != "image/png" && file['type'] != "image/gif")) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputImage = this.$refs.fileInputImage;
                    inputImage.type = 'text';
                    inputImage.type = 'file';

                    this.form.image = "";
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                };
                reader.readAsDataURL(file);
            },
            deletedImage(id) {
                swal.fire({
                    title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                    text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ลบข้อมูล!',
                    cancelButtonText: 'ยกเลิก!',
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.get('/api/slide/deletedImage/' + id).then(() => {
                            swal.fire(
                                'Deleted!',
                                'ลบข้อมูลไฟล์รูปภาพเรียบร้อยแล้ว',
                                'success'
                            );
                            this.form.image = "";
                        }).catch(() => {
                            swal.fire(
                                'Error!',
                                'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                'error'
                            );
                        });
                    }
                })
            },
            updateSlide() {
                var app = this;
                this.form.post('/api/slide/' + app.form.id)
                    .then(() => {
                        this.loadData();
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });

                        const inputImage = this.$refs.fileInputImage;
                        inputImage.type = 'text';
                        inputImage.type = 'file';

                        this.form.image = "";
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        }
    }
</script>
