<template>
    <div class="same-style cart-wrap">
        <button class="icon-cart" @click="isHidden = !isHidden">
            <i class="fas fa-shopping-bag"></i>
            <span class="count-style">{{ this.$parent.badge }}</span>
        </button>

        <template v-if="!isHidden">
        <div :class="{ 'shopping-cart cart-visible' : !isHidden}">
            <ul>
                <li class="single-shopping-cart" v-for="(cart, n) in this.$parent.carts" :key="n">
                    <div class="shopping-cart-img">
                        <a :href="'/product/' + cart.titleLink" v-if="cart.image"><img alt="" :src="'/upload/product/image/thumbnail/' + cart.image" width="82"></a>
                        <a :href="'/product/' + cart.titleLink" v-if="!cart.image"><img alt="" :src="'/frontEnd/template/assets/images/noImage.png'" width="82"></a>
                    </div>
                    <div class="shopping-cart-title">
                        <h4>
                            <a :href="'/product/' + cart.titleLink">{{ cart.title }}</a><br>
                            <small v-if="cart.options.length">
                                [<span v-for="(option, o) in cart.options" :key="option.title">{{ option.title }}: {{ option.value }}<span v-if="cart.options.length != (o + 1)">, </span></span>]
                            </small>
                        </h4>
                        <h6>Qty: {{ cart.quantity }}</h6>
                        <span v-if="cart.priceSpecial != 0 && cart.priceSpecial != null"><s>฿{{ $filters.formatPrice(cart.price) }}</s> ฿{{ $filters.formatPrice(cart.priceSpecial) }}</span>
                        <span v-if="cart.priceSpecial == 0 || cart.priceSpecial == null">฿{{ $filters.formatPrice(cart.price) }}</span>
                    </div>
                    <div class="shopping-cart-delete">
                        <a href="javascript:void(0)" @click="removeCart(n)"><i class="fa fa-times-circle"></i></a>
                    </div>
                </li>
            </ul>
            <div class="shopping-cart-total">
                <h4><strong>Total :</strong> <span class="shop-total">฿{{ $filters.formatPrice(this.$parent.totalPrice) }}</span></h4>
            </div>
            <div class="shopping-cart-btn btn-hover text-center">
                <a class="default-btn" :href="'/cart'">view cart</a>
                <a class="default-btn" :href="'/checkout'">checkout</a>
            </div>
        </div>
        </template>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                isHidden: true,
            }
        },
        methods: {
            viewCart() {
                if(localStorage.getItem('carts')) {
                    let dataPrice;

                    this.$parent.carts = JSON.parse(localStorage.getItem('carts'));

                    for (let i = 0; i < this.$parent.carts.length; i++) {
                        if(this.$parent.carts[i].options.length != 0){
                            // sort by name
                            this.$parent.carts[i].options.sort(function(a, b) {
                                const nameA = a.title.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.title.toUpperCase(); // ignore upper and lowercase

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            });
                        }
                    }

                    this.$parent.badge = this.$parent.carts.length;
                    this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                        dataPrice = ((item.priceSpecial == 0 || item.priceSpecial == null) ? item.price : item.priceSpecial);
                        return total + (item.quantity * dataPrice);
                    }, 0);
                    this.$parent.couponID = parseInt(localStorage.getItem('couponID'));
                    this.$parent.couponCode = localStorage.getItem('couponCode');
                    this.$parent.totalDiscount = parseInt(localStorage.getItem('totalDiscount'));
                } else {
                    this.$parent.badge = 0;
                    localStorage.setItem('carts', '[]');
                }
            },
            storeCart() {
                let parsed = JSON.stringify(this.$parent.carts);
                localStorage.setItem('carts', parsed);
                this.viewCart();
            },
            removeCart(n) {
                swal.fire({
                    title: 'ต้องการลบข้อมูลใช่หรือไม่ ?',
                    text: "หากคุณตอบตกลง จะไม่สามารถยกเลิกสิ่งนี้ได้!",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ลบข้อมูล!',
                    cancelButtonText: 'ยกเลิก!',
                }).then((result) => {
                    if (result.value) {
                        this.$parent.carts.splice(n, 1);
                        this.storeCart();
                    }
                });
            },
        },
        created() {
            this.viewCart();
        },
    }
</script>