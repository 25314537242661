<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Setting Management</h3>
                        <small>Edit and manage setting permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="updateSetting">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('titleBar') }">
                                        <label for="namtitleBare">ข้อความบนไตเติ้ลบาร์</label>
                                        <div class="input-group">
                                            <input v-model="form.titleBar" type="text" class="form-control" id="titleBar" name="titleBar" aria-describedby="length-name" @click="hideError('titleBar')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ max - form.titleBar.length }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('titleBar')">
                                            <span class="help-block">
                                                <has-error :form="form" field="titleBar"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="description">รายละเอียดเว็บไซต์</label>
                                        <textarea v-model="form.description" class="form-control" rows="10" id="description" name="description"></textarea>
                                        <small class="text-green">จะแสดงในส่วน เมต้าแทค ซึ่งเสิร์ซเอนจิ้น จะสนใจข้อมูลนี้ เพื่อสร้างลิงก์มาที่เว็บไซต์ของคุณ</small>
                                    </div>

                                    <div class="form-group">
                                        <label>คีย์เวิร์ด (Press "ENTER" to add data "คีย์เวิร์ด")</label>
                                        <!-- <tags-input v-model="form.keywords" element-id="tags" :typeahead="true"></tags-input> -->
                                        <tags-input v-model="form.keyword" :tags="form.keywords" @tags-changed="newTags=>form.keywords=newTags"></tags-input>
                                        <small class="text-green">เป็นคำที่ เสิร์ซเอ็นจิ้น ไว้ค้นหาเว็บไซต์ของคุณ ดังนั้นควรใส่คำที่เกี่ยวข้องกับธุรกิจคุณเท่านั้น</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="rights">คำสงวนลิขสิทธิ์</label>
                                        <textarea v-model="form.rights" class="form-control" rows="3" id="rights" name="rights"></textarea>
                                        <small class="text-green">จะแสดงอยู่ด้านล่างของเว็บไซต์ท่าน หากต้องการขึ้นบรรทัดใหม่ สามารถใส่แทค &lang;br&rang; ขั้นกลางข้อความนั้นได้</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="facebook">Facebook</label>
                                        <input v-model="form.facebook" type="input" class="form-control" id="facebook" name="facebook">
                                        <small class="text-green">Facebook URL วิธีทำ URL Fanpage http://www.facebook.com/username</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="twitter">Twitter</label>
                                        <input v-model="form.twitter" type="input" class="form-control" id="twitter" name="twitter">
                                        <small class="text-green">Twitter URL :: http://www.twitter.com</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="lineID">Line ID</label>
                                        <input v-model="form.lineID" type="input" class="form-control" id="lineID" name="lineID">
                                        <small class="text-green">Line ID URL :: https://line.me/th</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="instagram">Instagram</label>
                                        <input v-model="form.instagram" type="input" class="form-control" id="instagram" name="instagram">
                                        <small class="text-green">Instagram URL :: http://www.instagram.com</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="youtube">Youtube</label>
                                        <input v-model="form.youtube" type="input" class="form-control" id="youtube" name="youtube">
                                        <small class="text-green">Youtube URL :: http://www.youtube.com</small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row" v-if="form.favicon">
                                        <div class="col-md-12 text-right">
                                            <div class="form-group">
                                                <img class="img-thumbnail" :src="getFavicon()" alt="Favicon Image">
                                                <p v-if="form.favicon.length < 200"><a style="cursor: pointer; color: #fff;" class="btn btn-danger btn-sm mt-1" @click="deletedFavicon(form.id)"><i class="fa fa-trash"></i> ลบไฟล์รูปภาพ</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="favicon">Favicon Icon <small class="text-green">ไอค่อนเล็กๆ มีไว้บอกถึงความเป็นเจ้าของเว็บไซต์</small></label>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" id="favicon" name="favicon" ref="fileInputFavicon" class="form-control" @change="uploadFavicon">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-if="form.logo">
                                        <div class="col-md-12 text-right">
                                            <div class="form-group">
                                                <img class="img-thumbnail" :src="getLogo()" alt="Logo Image" style="width: 250px; background-color: rgb(0, 0, 0);">
                                                <p v-if="form.logo.length < 200"><a style="cursor: pointer; color: #fff;" class="btn btn-danger btn-sm mt-1" @click="deletedLogo(form.id)"><i class="fa fa-trash"></i> ลบไฟล์รูปภาพ</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="logo">Logo Website <small class="text-green">โลโก้แสดงลักษณะของเว็บไซต์</small></label>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" id="logo" name="logo" ref="fileInputLogo" class="form-control" @change="uploadLogo">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    id: '',
                    favicon: '',
                    logo: '',
                    titleBar: '',
                    description: '',
                    keyword: '',
                    keywords: [],
                    rights: '',
                    facebook: '',
                    twitter: '',
                    lineID: '',
                    instagram: '',
                    youtube: '',
                }),
                max: 255,
            }
        },
        mounted() {
            if (this.$gate.isAdmin()) {
                let app = this;
                axios.get('/api/setting')
                    .then(function(resp) {
                        app.form.fill(resp.data.dataSetting);
                        if(resp.data.dataSetting.keywords) {
                            app.form.keywords = JSON.parse(resp.data.dataSetting.keywords);
                        }
                    })
                    .catch(function() {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            loadData() {
                if (this.$gate.isAdmin()) {
                    let app = this;
                    axios.get('/api/setting')
                        .then(function(resp) {
                            app.form.fill(resp.data.dataSetting);
                            if(resp.data.dataSetting.keywords) {
                                app.form.keywords = JSON.parse(resp.data.dataSetting.keywords);
                            }
                        })
                        .catch(function() {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            getFavicon(){
                if(this.form.favicon) {
                    let imageFavicon = (this.form.favicon.length > 200) ? this.form.favicon : "/upload/setting/favicon/" + this.form.favicon;
                    return imageFavicon;
                } else {
                    return null;
                }
            },
            getLogo(){
                if(this.form.logo) {
                    let imageLogo = (this.form.logo.length > 200) ? this.form.logo : "/upload/setting/logo/" + this.form.logo;
                    return imageLogo;
                } else {
                    return null;
                }
            },
            uploadFavicon(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if ((file['type'] != "image/jpg" && file['type'] != "image/jpeg" && file['type'] != "image/png" && file['type'] != "image/gif" && file['type'] != "image/webp" && file['type'] != "image/j2k" && file['type'] != "image/jp2" && file['type'] != "image/jxr")) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputFavicon = this.$refs.fileInputFavicon;
                    inputFavicon.type = 'text';
                    inputFavicon.type = 'file';

                    this.form.favicon = "";
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.favicon = reader.result;
                };
                reader.readAsDataURL(file);
            },
            uploadLogo(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if ((file['type'] != "image/jpg" && file['type'] != "image/jpeg" && file['type'] != "image/png" && file['type'] != "image/gif" && file['type'] != "image/webp" && file['type'] != "image/j2k" && file['type'] != "image/jp2" && file['type'] != "image/jxr")) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputLogo = this.$refs.fileInputLogo;
                    inputLogo.type = 'text';
                    inputLogo.type = 'file';

                    this.form.logo = "";
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.logo = reader.result;
                };
                reader.readAsDataURL(file);
            },
            deletedFavicon(id) {
                swal.fire({
                    title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                    text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ลบข้อมูล!',
                    cancelButtonText: 'ยกเลิก!',
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.get('/api/setting/deletedFavicon/' + id).then(()=>{
                            swal.fire(
                                'Deleted!',
                                'ลบข้อมูลไฟล์รูปภาพ Favicon Website เรียบร้อยแล้ว',
                                'success'
                            );
                            this.form.favicon = "";
                        }).catch(()=> {
                            swal.fire(
                                'Error!',
                                'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                'error'
                            );
                        });
                    }
                })
            },
            deletedLogo(id) {
                swal.fire({
                    title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                    text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ลบข้อมูล!',
                    cancelButtonText: 'ยกเลิก!',
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.get('/api/setting/deletedLogo/' + id).then(()=>{
                            swal.fire(
                                'Deleted!',
                                'ลบข้อมูลไฟล์รูปภาพ Logo Website เรียบร้อยแล้ว',
                                'success'
                            );
                            this.form.logo = "";
                        }).catch(()=> {
                            swal.fire(
                                'Error!',
                                'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                'error'
                            );
                        });
                    }
                })
            },
            updateSetting() {
                var app = this;
                this.form.post('/api/setting')
                    .then(() => {
                        this.loadData();
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });

                        const inputFavicon = this.$refs.fileInputFavicon;
                        inputFavicon.type = 'text';
                        inputFavicon.type = 'file';

                        this.form.favicon = "";

                        const inputLogo = this.$refs.fileInputLogo;
                        inputLogo.type = 'text';
                        inputLogo.type = 'file';

                        this.form.logo = "";
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        }
    }
</script>
