export default class Gate {
    constructor(user){
        this.user = user;
    }
    isAdmin(){
        return this.user.role === 'admin';
    }
    isManager(){
        return this.user.role === 'manager';
    }
    isUser(){
        return this.user.role === 'user';
    }
    isAdminOrUser(){
        if(this.user.role === 'admin' || this.user.role === 'user'){
            return true;
        }
    }
}
