<template>
    <div>
        <div class="billing-info-wrap" v-if="options">
    
            <div class="billing-select mb-20" v-for="option in options" :key="option.id">
                <label>{{ option.title }}</label>
                <select @change="addOption(option.title, $event)">
                    <option>Select a {{ option.title }}</option>
                    <option v-for="dataOption in option.option" :key="dataOption.value" :value="dataOption.value">{{ dataOption.value }}</option>
                </select>
            </div>
    
        </div>
        <div class="pro-details-quality">
            <div class="cart-plus-minus">
                <div @click="minusAmount()" class="dec qtybutton">-</div>
                <input class="cart-plus-minus-box" type="text" name="quantity" v-model="form.quantity">
                <div @click="plusAmount()" class="inc qtybutton">+</div>
            </div>
            <div class="pro-details-cart btn-hover">
                <a @click="addCart(form)">Add To Cart</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataId: '',
                form: {
                    id: '',
                    image: '',
                    title: '',
                    titleLink: '',
                    price: '',
                    priceSpecial: '',
                    optionSelecteds: [],
                    quantity: 1,
                },
                cartAdd: {
                    id: '',
                    image: '',
                    title: '',
                    titleLink: '',
                    price: '',
                    priceSpecial: '',
                    options: [],
                    quantity: '',
                },
                options: [],
            }
        },
        props: ['id'],
        methods: {
            minusAmount() {
                if(this.form.quantity > 0) {
                    this.form.quantity = parseInt(this.form.quantity) - 1;
                }
            },
            plusAmount() {
                this.form.quantity = parseInt(this.form.quantity) + 1;
            },
            addOption(optionTitle, event) {
                let app = this;

                const itemOptionTitle = app.form.optionSelecteds.find(({ title }) => title === optionTitle);
                if(!itemOptionTitle) {
                    app.form.optionSelecteds.push({
                        title: optionTitle,
                        value: event.target.value,
                    });
                } else {
                    app.form.optionSelecteds = app.form.optionSelecteds.filter(x => {
                        return x.title != itemOptionTitle.title;
                    })

                    app.form.optionSelecteds.push({
                        title: optionTitle,
                        value: event.target.value,
                    });
                }
            },
            viewCart() {
                if(localStorage.getItem('carts')) {
                    let dataPrice;

                    this.$parent.carts = JSON.parse(localStorage.getItem('carts'));
                    this.$parent.badge = this.$parent.carts.length;
                    this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                        dataPrice = ((item.priceSpecial == 0) ? item.price : item.priceSpecial);
                        return total + (item.quantity * dataPrice);
                    }, 0);
                    this.$parent.couponID = parseInt(localStorage.getItem('couponID'));
                    this.$parent.couponCode = localStorage.getItem('couponCode');
                    this.$parent.totalDiscount = parseInt(localStorage.getItem('totalDiscount'));
                }
            },
            addCart(form) {
                const cartItem = this.$parent.carts.find(c => c.id === form.id);
                if(!cartItem) {
                    if(this.options) {
                        if(this.options.length != form.optionSelecteds.length) {
                            toast.fire({
                                icon: 'error',
                                title: 'กรุณาเลือกคุณสมบัติสินค้า !!'
                            });
                        } else {
                            this.cartAdd.id = form.id;
                            this.cartAdd.image = form.image;
                            this.cartAdd.title = form.title;
                            this.cartAdd.titleLink = form.titleLink;
                            this.cartAdd.price = form.price;
                            this.cartAdd.priceSpecial = form.priceSpecial;
                            this.cartAdd.options = form.optionSelecteds;
                            this.cartAdd.quantity = form.quantity;
                            this.$parent.carts.push(this.cartAdd);
                            this.cartAdd = {};
                            this.storeCart();

                            toast.fire({
                                icon: 'success',
                                title: 'เพิ่มสินค้าเรียบร้อย !!'
                            });
                            
                            window.location.href = '/cart';
                        }
                    } else {
                        this.cartAdd.id = form.id;
                        this.cartAdd.image = form.image;
                        this.cartAdd.title = form.title;
                        this.cartAdd.titleLink = form.titleLink;
                        this.cartAdd.price = form.price;
                        this.cartAdd.priceSpecial = form.priceSpecial;
                        this.cartAdd.options = form.optionSelecteds;
                        this.cartAdd.quantity = form.quantity;
                        this.$parent.carts.push(this.cartAdd);
                        this.cartAdd = {};
                        this.storeCart();

                        toast.fire({
                            icon: 'success',
                            title: 'เพิ่มสินค้าเรียบร้อย !!'
                        });

                        window.location.href = '/cart';
                    }
                } else {
                    if(form.optionSelecteds.length == 0) {
                        toast.fire({
                            icon: 'error',
                            title: 'สินค้ารายการนี้อยู่ในตะกร้า !!',
                            // timer: 500000
                        });
                    } else {
                        if(this.options.length != form.optionSelecteds.length) {
                            toast.fire({
                                icon: 'error',
                                title: 'กรุณาเลือกคุณสมบัติสินค้า !!'
                            });
                        } else {
                            let isTure;
                            let cartsOptions = [];
                            let cartsOptionSelecteds = [];

                            // sort by name
                            form.optionSelecteds.sort(function(a, b) {
                                const nameA = a.title.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.title.toUpperCase(); // ignore upper and lowercase

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            });

                            const cartItemCh = this.$parent.carts.filter(c => c.id === form.id);
                            // console.log(cartItemCh);

                            for (let i = 0; i < cartItemCh.length; i++) {
                                if(cartItemCh[i].options) {
                                    for (let j = 0; j < cartItemCh[i].options.length; j++) {
                                        cartsOptions.push({
                                            title: cartItemCh[i].options[j].title,
                                            value: cartItemCh[i].options[j].value,
                                        });
                                    }
                                }
                            }
                            // console.log(cartsOptions);
                            // console.log('***********************************');

                            for (let i = 0; i < form.optionSelecteds.length; i++) {
                                cartsOptionSelecteds.push({
                                    title: form.optionSelecteds[i].title,
                                    value: form.optionSelecteds[i].value,
                                });
                            }
                            // console.log(cartsOptionSelecteds);
                            // console.log('***********************************');

                            for (let i = 0; i < cartsOptionSelecteds.length; i++) {

                                // console.log(cartsOptionSelecteds[i].title + ' && ' + cartsOptionSelecteds[i].value);
                                
                                const chCartsOption = cartsOptions.find( ({ title, value }) => title === cartsOptionSelecteds[i].title && value === cartsOptionSelecteds[i].value );
                                // console.log(chCartsOption);

                                if(chCartsOption) {
                                    isTure = true;
                                } else {
                                    isTure = false;
                                    break;
                                }
                            }

                            if(isTure == false) {
                                this.cartAdd.id = form.id;
                                this.cartAdd.image = form.image;
                                this.cartAdd.title = form.title;
                                this.cartAdd.titleLink = form.titleLink;
                                this.cartAdd.price = form.price;
                                this.cartAdd.priceSpecial = form.priceSpecial;
                                this.cartAdd.options = form.optionSelecteds;
                                this.cartAdd.quantity = form.quantity;
                                this.$parent.carts.push(this.cartAdd);
                                this.cartAdd = {};
                                this.storeCart();

                                toast.fire({
                                    icon: 'success',
                                    title: 'เพิ่มสินค้าในตะกร้าสินค้าเรียบร้อย !!'
                                });

                                window.location.href = '/cart';
                            } else {
                                toast.fire({
                                    icon: 'error',
                                    title: 'สินค้ารายการนี้อยู่ในตะกร้าสินค้าเรียบร้อยแล้ว !!.......'
                                });
                            }
                        }
                    }
                }
            },
            storeCart() {
                let parsed = JSON.stringify(this.$parent.carts);
                localStorage.setItem('carts', parsed);
                this.viewCart();
            },
        },
        mounted() {
            let app = this;
            let id = this.id;
            app.dataId = id;
            axios.get('/api/getDataProduct/' + id)
            .then(function (response) {
                app.form.id = response.data.product.id;
                app.form.image = response.data.product.image;
                app.form.title = response.data.product.title;
                app.form.titleLink = response.data.product.titleLink;
                app.form.price = response.data.product.price;
                app.form.priceSpecial = response.data.product.priceSpecial;
                app.options = response.data.productOptions;

                app.options.sort(function(a, b) {
                    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.title.toUpperCase(); // ignore upper and lowercase

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });

                app.storeCart();
            })
            .catch(function () {
                // window.location.href = '/our-products';
            });
        },
    }
</script>