<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Orders Management</h3>
                        <small>Edit and manage order permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-12">
                <div class="card">
                    <div class="body">
                        <form role="form" @submit.prevent="searchedOrder()">
                            <div class="row pb-2">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input v-model="form.orderID" type="text" class="form-control" id="orderID" name="orderID" placeholder="เลขที่ใบสั่งซื้อสินค้า" aria-label="เลขที่ใบสั่งซื้อสินค้า" aria-describedby="search-data">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <Datepicker v-model="form.orderDate" range :format="'dd/MM/yyyy'" :enable-time-picker="false" placeholder="วันที่สั่งซื้อสินค้า"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <Datepicker v-model="form.paypalDate" range :format="'dd/MM/yyyy'" :enable-time-picker="false" placeholder="วันที่ชำระเงินโดย Paypal"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-primary btn-lg btn-block" type="submit"><i class="fa-solid fa-magnifying-glass"></i> Search</button>
                                      </div>
                                </div>
                            </div>
                        </form>

                        <!--PC-->
                        <div class="row d-none d-sm-block">
                            <div class="col-sm-12">
                                <div class="table-content table-vertical-align-top mt-2">
                                    <DataTable 
                                        :headers="headers"
                                        :items="items"
                                        :rows-per-page="50"
                                        show-index
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>
            
                                        <!--Header-->
                                        <template #header-order="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-paystatus="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-shipstatus="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-parceltrack="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-manage="header">
                                            <div class="header-text text-center manage">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-order="{ id, billingAddressFName, billingAddressLName, badge, totalPrice, totalDiscount }">
                                            <div class="text-left">
                                                <p class="mb-0">เลขที่ใบสั่งซื้อ <router-link :to="{name: 'editedOrder', params: {id: id}}">{{ id }}</router-link></p>
                                                <p>ชื่อลูกค้า {{ billingAddressFName }} {{ billingAddressLName }}</p>
                                                <p>จำนวนสินค้าที่สั่งซื้อ [{{ badge }} รายการ] <b>[ราคารวม {{ $filters.formatPrice((totalPrice - totalDiscount)) }} บาท]</b></p>
                                            </div>
                                        </template>
                                        <template #item-paystatus="{ paystatus, paystatusTitle }">
                                            <div v-if="paystatus" class="text-center">
                                                <span v-if="paystatusTitle == 'รอการชำระเงิน'" style="font-size: 100%" class="btn btn-block bg-warning btn-text-white"><i class="fas fa-money-bill-wave"></i> {{ paystatusTitle }}</span>
                                                <span v-if="paystatusTitle == 'ชำระเงินเรียบร้อย'" style="font-size: 100%" class="btn btn-block bg-success btn-text-white"><i class="fas fa-money-bill-wave"></i> {{ paystatusTitle }}</span>
                                                <span v-if="paystatusTitle == 'ยกเลิกการสั่งซื้อ'" style="font-size: 100%" class="btn btn-block bg-danger btn-text-white"><i class="fas fa-money-bill-wave"></i> {{ paystatusTitle }}</span>
                                            </div>
                                        </template>
                                        <template #item-shipstatus="{ shipstatus, shipstatusTitle }">
                                            <div v-if="shipstatus" class="text-center">
                                                <span style="font-size: 100%" class="btn btn-block bg-info btn-text-white"><i class="fa-solid fa-boxes-packing"></i> {{ shipstatusTitle }}</span>
                                            </div>
                                        </template>
                                        <template #item-parceltrack="{ parceltrack, parceltrackTitle, parceltrackLink, parceltrackNumber }">
                                            <div class="text-center">
                                                <a :href="parceltrackLink" v-if="parceltrack" class="btn btn-block bg-secondary btn-text-white">
                                                    <i class="fas fa-truck"></i> {{ parceltrackTitle }}
                                                </a>
                                                <p class="pt-2" v-if="parceltrackNumber"><b>Tracking:</b> {{ parceltrackNumber }}</p>
                                            </div>
                                        </template>
                                        <template #item-manage="{ id, confirmOrder }">
                                            <div class="text-center">
                                                <router-link :to="{name: 'editedOrder', params: {id: id}}">
                                                    <button type="button" class="btn btnEdit" title="Edit"><i class="fa-regular fa-pen-to-square"></i></button>
                                                </router-link>

                                                <button type="button" class="btn btnDelete" @click="deletedOrder(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>

                                                <button type="button" class="btn btnConfirmOrder" v-if="confirmOrder == '1'" title="Confirm order"><i class="fa-solid fa-circle-check"></i></button>
                                                <button type="button" class="btn btnOrder" v-if="confirmOrder == '0'" @click="confirmedOrder(id)" title="Waiting to be confirmed"><i class="fa-solid fa-hourglass-half"></i></button>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                        <!--MOBILE-->
                        <div class="row d-block d-sm-none">
                            <div class="col-sm-12">
                                <div class="table-content mt-2">
                                    <DataTable 
                                        ref="dataTableMobile" 
                                        :headers="headerMobiles"
                                        :items="items"
                                        :rows-per-page="20"
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>

                                        <!--Header-->
                                        <template #header-order="header">
                                            <div class="header-text text-center">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-order="{ id, billingAddressFName, billingAddressLName, badge, totalPrice, totalDiscount, paystatus, paystatusTitle, shipstatus, shipstatusTitle, parceltrack, parceltrackTitle, parceltrackLink, parceltrackNumber, confirmOrder}">
                                            <div class="dataMobile">
                                                <label for="order">เลขที่ใบสั่งซื้อ:</label>
                                                <p class="mb-0">
                                                    <span v-if="!id">-</span>
                                                    <span v-if="id">{{ id }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="name">ชื่อลูกค้า:</label>
                                                <p class="mb-0">
                                                    <span v-if="!billingAddressFName">-</span>
                                                    <span v-if="billingAddressFName">{{ billingAddressFName }} {{ billingAddressLName }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="badge">จำนวนสินค้าที่สั่งซื้อ:</label>
                                                <p class="mb-0">
                                                    <span v-if="!badge">-</span>
                                                    <span v-if="badge">[{{ badge }} รายการ] <b>[ราคารวม {{ (totalPrice - totalDiscount) }} บาท]</b></span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="paystatus">สถานะการชำระเงิน:</label>
                                                <p class="mb-0">
                                                    <span v-if="!paystatus">-</span>
                                                    <span v-if="paystatus">{{ paystatusTitle }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="shipstatus">สถานะการจัดส่งสินค้า:</label>
                                                <p class="mb-0">
                                                    <span v-if="!shipstatus">-</span>
                                                    <span v-if="shipstatus">{{ shipstatusTitle }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="parceltrack">รูปแบบของการจัดส่ง:</label>
                                                <p class="mb-0">
                                                    <span v-if="!parceltrack">-</span>
                                                    <span v-if="parceltrack">
                                                        <a :href="parceltrackLink" class="btn btn-block bg-warning btn-sm" target="_blank"><i class="fas fa-truck"></i> {{ parceltrackTitle }}</a>
                                                        <span v-if="parceltrackNumber">{{ parceltrackNumber }}</span>
                                                    </span>
                                                </p>
                                            </div>
                                            
                                            <div class="dataMobile">
                                                <label for="manage">Manage:</label>
                                                <p class="mb-0">
                                                    <button type="button" class="btn btnConfirmOrder" v-if="confirmOrder == '1'" title="Confirm order"><i class="fa-solid fa-circle-check"></i></button>
                                                    <button type="button" class="btn btnOrder" v-if="confirmOrder == '0'" @click="confirmOrder(id)" title="Waiting to be confirmed"><i class="fa-solid fa-hourglass-half"></i></button>

                                                    <button type="button" class="btn btnDelete mr-0" @click="deletedOrder(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>
                                                </p>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            if(this.$gate.isAdmin()) {
                return {
                    form: new Form({
                        orderID: null,
                        orderDate: null,
                        paypalDate: null,
                    }),
                    isSearch: false,
                    headers: [
                        { text: "ORDER", value: "order", sortable: true, width: 'auto' },
                        { text: "PAYSTATUS", value: "paystatus", sortable: true, width: 230 },
                        { text: "SHIPSTATUS", value: "shipstatus", sortable: true, width: 230 },
                        { text: "PARCELTRACK", value: "parceltrack", sortable: true, width: 230 },
                        { text: "MANAGE", value: "manage", fixed: true, width: 160 },
                    ],
                    headerMobiles: [
                        { text: "ORDER", value: "order", sortable: true, width: 'auto' },
                    ],
                    items: [],
                }
            } else {
                return {}
            }
        },
        created() {
            this.loadOrders();
        },
        methods: {
            searchedOrder() {
                if(this.$gate.isAdmin()) {
                    this.isSearch = true;
                    let queryOrderID = this.form.orderID;
                    let queryOrderDate = (this.form.orderDate ? new Date(this.form.orderDate[0]).toISOString() + ',' + new Date(this.form.orderDate[1]).toISOString() : null);
                    let queryPayDate = (this.form.paypalDate ? new Date(this.form.paypalDate[0]).toISOString() + ',' + new Date(this.form.paypalDate[1]).toISOString() : null);

                    axios.get('/api/order/findOrder?orderID=' + queryOrderID + '&orderDate=' + queryOrderDate + '&paypalDate=' + queryPayDate)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            getResults(page = 1) {
                if(this.$gate.isAdmin()) {
                    if(this.isSearch === true) {
                        let queryOrderID = this.form.orderID;
                        let queryOrderDate = (this.form.orderDate ? new Date(this.form.orderDate[0]).toISOString() + ',' + new Date(this.form.orderDate[1]).toISOString() : null);
                        let queryPayDate = (this.form.paypalDate ? new Date(this.form.paypalDate[0]).toISOString() + ',' + new Date(this.form.paypalDate[1]).toISOString() : null);
                        axios.get('/api/order/findOrder?orderID=' + queryOrderID + '&orderDate=' + queryOrderDate + '&paypalDate=' + queryPayDate + '&page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    } else {
                        axios.get('/api/order?page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    }
                }
            },
            loadOrders() {
                if(this.$gate.isAdmin()) {
                    axios.get('/api/order')
                    .then(response => {
                        this.items = response.data;
                    })
                    .catch(function () {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
                }
            },
            confirmedOrder(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการยืนยันคำสั่งซื้อใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ยืนยันคำสั่งซื้อ!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/order/confirm/'+id).then(()=>{
                                swal.fire(
                                    'Confirm!',
                                    'ยืนยันคำสั่งซื้อเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadOrders();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
            deletedOrder(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ลบข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/order/delete/'+id).then(()=>{
                                swal.fire(
                                    'Deleted!',
                                    'ลบข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadOrders();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
        }
    }
</script>
