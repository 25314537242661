<template>
    <div class="container">
        <div class="row flex-row-reverse">
            <div class="col-lg-12">
                <h2 class="text-center pt-30 pb-30">Checkout<small>(1.6)</small></h2>
            </div>
        </div>
        <form role="form" @submit.prevent="createOrder()" v-if="this.$parent.badge != 0">
            <div class="row">
                <div class="col-lg-6">
                    <div class="billing-info-wrap">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressFName') }">
                                    <label>First Name</label>
                                    <input v-model="form.billingAddressFName" type="text" @click="hideError('billingAddressFName')">
                                    <has-error :form="form" field="billingAddressFName"></has-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressLName') }">
                                    <label>Last Name</label>
                                    <input v-model="form.billingAddressLName" type="text" @click="hideError('billingAddressLName')">
                                    <has-error :form="form" field="billingAddressLName"></has-error>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressAddress') }">
                                    <label>Address</label>
                                    <textarea v-model="form.billingAddressAddress" rows="5" @click="hideError('billingAddressAddress')"></textarea>
                                    <has-error :form="form" field="billingAddressAddress"></has-error>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressPostalCode') }">
                                    <label>Zip code</label>
                                    <input v-model="form.billingAddressPostalCode" type="number" step="0" min="0" :maxlength="max" @click="hideError('billingAddressPostalCode')">
                                    <has-error :form="form" field="billingAddressPostalCode"></has-error>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressEmail') }">
                                    <label>Email</label>
                                    <input v-model="form.billingAddressEmail" type="text" @click="hideError('billingAddressEmail')">
                                    <has-error :form="form" field="billingAddressEmail"></has-error>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('billingAddressTelephone') }">
                                    <label>Telephone</label>
                                    <input v-model="form.billingAddressTelephone" type="text" @click="hideError('billingAddressTelephone')">
                                    <has-error :form="form" field="billingAddressTelephone"></has-error>
                                </div>
                            </div>
                        </div>
                        <div class="additional-info-wrap">
                            <h4>Additional information</h4>
                            <div class="additional-info">
                                <label>Order notes</label>
                                <textarea v-model="form.ect" type="text" rows="5"></textarea>
                            </div>
                        </div>
                        <div class="checkout-account mt-25">
                            <input class="checkout-toggle" type="checkbox" v-model="form.shippingDiffrentAddress" v-on:click="setShippingDiffrentAddress(); isHiddenDifferentAddress = !isHiddenDifferentAddress">
                            <span>Ship to a different address?</span>
                        </div>

                        <div class="different-address mt-30" v-if="!isHiddenDifferentAddress">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressFName') }">
                                        <label>First Name</label>
                                        <input v-model="form.shippingAddressFName" type="text" @click="hideError('shippingAddressFName')">
                                        <has-error :form="form" field="shippingAddressFName"></has-error>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressLName') }">
                                        <label>Last Name</label>
                                        <input v-model="form.shippingAddressLName" type="text" @click="hideError('shippingAddressLName')">
                                        <has-error :form="form" field="shippingAddressLName"></has-error>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressAddress') }">
                                        <label>Address</label>
                                        <textarea v-model="form.shippingAddressAddress" rows="5" @click="hideError('shippingAddressAddress')"></textarea>
                                        <has-error :form="form" field="shippingAddressAddress"></has-error>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressPostalCode') }">
                                        <label>Zip code</label>
                                        <input v-model="form.shippingAddressPostalCode" type="number" step="0" min="0" :maxlength="max" @click="hideError('shippingAddressPostalCode')">
                                        <has-error :form="form" field="shippingAddressPostalCode"></has-error>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressEmail') }">
                                        <label>Email</label>
                                        <input v-model="form.shippingAddressEmail" type="text" @click="hideError('shippingAddressEmail')">
                                        <has-error :form="form" field="shippingAddressEmail"></has-error>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20" :class="{ 'u-has-error': form.errors.has('shippingAddressTelephone') }">
                                        <label>Telephone</label>
                                        <input v-model="form.shippingAddressTelephone" type="text" @click="hideError('shippingAddressTelephone')">
                                        <has-error :form="form" field="shippingAddressTelephone"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="your-order-area">
                        <div class="your-order-wrap gray-bg-4">
                            <div class="your-order-product-info">
                                <div class="your-order-top">
                                    <ul>
                                        <li>Product</li>
                                        <li>Total</li>
                                    </ul>
                                </div>
                                <div class="your-order-middle">
                                    <ul>
                                        <li v-for="cart in this.form.carts" :key="cart.id">
                                            <span class="order-middle-left">
                                                ({{ cart.quantity }}) {{ cart.title }} <br> 
                                                <small v-if="cart.options.length">
                                                    <span v-for="(option, o) in cart.options" :key="option.title">{{ option.title }}: {{ option.value }}<span v-if="cart.options.length != (o + 1)">, </span></span>
                                                </small>
                                            </span> 
                                            <span class="order-price" v-if="cart.priceSpecial != 0 && cart.priceSpecial != null">฿{{ $filters.formatPrice((cart.quantity * cart.priceSpecial)) }}</span>
                                            <span class="order-price" v-if="cart.priceSpecial == 0 || cart.priceSpecial == null">฿{{ $filters.formatPrice((cart.quantity * cart.price)) }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="your-order-bottom">
                                    <ul>
                                        <li class="your-order-shipping">Discount</li>
                                        <li>฿{{ $filters.formatPrice(this.form.totalDiscount) }}</li>
                                    </ul>
                                </div>
                                <div class="your-order-bottom">
                                    <ul>
                                        <li class="your-order-shipping">Shipping</li>
                                        <li>Free shipping</li>
                                    </ul>
                                </div>
                                <div class="your-order-total">
                                    <ul>
                                        <li class="order-total">Total</li>
                                        <li>฿{{ $filters.formatPrice((this.form.totalPrice - this.form.totalDiscount)) }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div class="Place-order mt-25">
                            <button :disabled='isDisabled' :class="{ 'cart-btn-2 disabled' : isDisabled }" class="btn-hover" v-if="wait == false">Place Order</button>
                            <button class="btn-hover" v-if="wait == true" disabled>Processing ...</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row" v-else>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <h3 class="text-center">You have no items in your shopping cart.</h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    billingAddressFName: '',
                    billingAddressLName: '',
                    billingAddressAddress: '',
                    billingAddressPostalCode: '',
                    billingAddressEmail: '',
                    billingAddressTelephone: '',
                    shippingDiffrentAddress: false,
                    shippingAddressFName: '',
                    shippingAddressLName: '',
                    shippingAddressAddress: '',
                    shippingAddressPostalCode: '',
                    shippingAddressTelephone: '',
                    ect: '',
                    carts: [],
                    badge: 0,
                    couponID: 0,
                    couponCode: 0,
                    totalDiscount: 0,
                    totalPrice: 0,
                }),
                wait: false,
                max: 5,
                isHiddenDifferentAddress: true,
                couponID: null,
                couponCode: null,
                totalDiscount: null,
                signature: null,
                isDisabled: true,
            }
        },
        methods: {
            checkCoupon() {
                // ดึงข้อมูลจาก localStorage
                this.couponID = localStorage.getItem('couponID');
                this.couponCode = localStorage.getItem('couponCode');
                this.totalDiscount = localStorage.getItem('totalDiscount');
                this.signature = localStorage.getItem('signature');

                // ตรวจสอบข้อมูล
                if (this.couponID || this.couponCode || this.totalDiscount || this.signature) {
                    // ส่งข้อมูลไปที่ API
                    axios.post('/api/checkCouponDiscount', {
                            couponID: parseInt(this.couponID),
                            couponCode: this.couponCode,
                            discountPrice: parseFloat(this.totalDiscount),
                            signature: this.signature,
                        })
                        .then((response) => {
                            this.isDisabled = false;
                        })
                        .catch((error) => {
                            this.clearCouponDiscount();
                            toast.fire({
                                icon: 'error',
                                title: error.response?.data?.message ?? 'Something went wrong. Please check again.',
                                showConfirmButton: false,
                                timer: 9000,
                                timerProgressBar: true,
                            });

                            this.form.couponID = 0;
                            this.form.couponCode = '';
                            this.form.totalDiscount = 0;
                        });
                } else {
                    this.clearCouponDiscount();
                    this.form.couponID = 0;
                    this.form.couponCode = '';
                    this.form.totalDiscount = 0;
                    this.isDisabled = false;
                }
            },
            clearCouponDiscount() {
                localStorage.removeItem('couponID');
                localStorage.removeItem('couponCode');
                localStorage.removeItem('totalDiscount');
                localStorage.removeItem('signature');
            },
            hideError(element) {
                this.form.errors.clear(element);
            },
            settingCheckout() {
                this.form.carts = this.$parent.carts;
                this.form.badge = this.$parent.badge;
                this.form.couponID = this.$parent.couponID;
                this.form.couponCode = this.$parent.couponCode;
                this.form.totalDiscount = this.$parent.totalDiscount;
                this.form.totalPrice = this.$parent.totalPrice;
            },
            setShippingDiffrentAddress() {
                if(this.form.shippingDiffrentAddress === true) {
                    this.form.shippingAddressFName = "";
                    this.form.shippingAddressLName = "";
                    this.form.shippingAddressAddress = "";
                    this.form.shippingAddressPostalCode = "";
                    this.form.shippingAddressTelephone = "";
                }
            },
            viewCart() {
                if(localStorage.getItem('carts')) {
                    let dataPrice;

                    this.$parent.carts = JSON.parse(localStorage.getItem('carts'));
                    this.$parent.badge = this.$parent.carts.length;

                    if(this.$parent.carts.length == 0) {
                        window.location.href = '/';
                    }
                    
                    this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                        dataPrice = ((item.priceSpecial == 0) ? item.price : item.priceSpecial);
                        return total + (item.quantity * dataPrice);
                    }, 0);

                    this.$parent.couponID = parseInt(localStorage.getItem('couponID'));
                    this.$parent.couponCode = localStorage.getItem('couponCode');
                    this.$parent.totalDiscount = parseFloat((localStorage.getItem('totalDiscount')) ? localStorage.getItem('totalDiscount') : 0);
                }
            },
            createOrder: async function () {
                var app = this;
                app.wait = true;

                try {
                    // เรียกใช้งาน reCAPTCHA และดึง token
                    const recaptchaToken = await grecaptcha.execute('6LeuQIIqAAAAAFmXfH9c1at0hNrQbjw2Z3rpV-du', { action: 'submit' });

                    // เพิ่ม reCAPTCHA token ในคำขอ POST
                    var order = "";
                    var signature = "";
                    this.form.post('/api/checkout', {
                        ...this.form, // ส่งข้อมูลฟอร์ม
                        recaptcha_token: recaptchaToken // เพิ่ม token
                    })
                    .then((response) => {
                        order = response.data.id;
                        signature = response.data.signature;

                        localStorage.removeItem('carts');
                        localStorage.removeItem('couponID');
                        localStorage.removeItem('couponCode');
                        localStorage.removeItem('totalDiscount');

                        window.location.href = '/confirm/' + order + '/' + signature;
                        // window.location.href = '/';
                    })
                    .catch((error) => {
                        app.wait = false;
                        toast.fire({
                            icon: 'error',
                            title: 'Something went wrong. Please check again.'
                        });
                    });

                } catch (error) {
                    console.error("reCAPTCHA error:", error);
                    app.wait = false;
                    toast.fire({
                        icon: 'error',
                        title: 'Unable to complete the request. Please try again.'
                    });
                }
            },
        },
        created() {
            this.checkCoupon();
            this.viewCart();
            this.settingCheckout();
        },
    }
</script>