//require('./bootstrap');

import { createApp } from 'vue'
const Vue = createApp()

import Gate from "./gate";
Vue.config.globalProperties.$gate = new Gate(window.user);

import axios from 'axios';
window.axios = axios;
// Set CSRF token as a common header for all Axios requests
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

import { Form } from 'vform';
window.Form = Form;

import { Button, HasError, AlertError, AlertErrors, AlertSuccess } from 'vform/src/components/bootstrap5'

import pagination from 'laravel-vue-pagination'

import VueTagsInput from '@sipec/vue3-tags-input';

import editor  from '@tinymce/tinymce-vue'

import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

import moment from 'moment';
moment.locale('th');

import numeral from 'numeral';

import swal from 'sweetalert2'
window.swal = swal;
const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});
window.toast = toast;

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import DataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

import HeaderCart from '../components/frontEnd/header/CartComponent.vue'

import QuantityProduct from '../components/frontEnd/product/QuantityComponent.vue'

import MainMenu from '../components/backEnd/menu/Menu.vue'

import NotFound from '../components/backEnd/notfound/Notfound.vue'

import router from "./router"

createApp({
    data() {
        return {
            carts: [],
            badge: 0,
            totalPrice: 0,
            couponID: 0,
            couponCode: 0,
            totalDiscount: 0,
        };
    },
})
.use({
    install(app) {
      app.config.globalProperties.$gate = new Gate(window.user),
      app.config.globalProperties.$filters = {
            upText(value) {
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upTextAll(value) {
                return value.toUpperCase()
            },
            textDateNoYaer(value) {
                return moment(value).format('Do MMMM')
            },
            textDateYaerThai(value) {
                return moment(new Date().setFullYear(parseInt(value.substring(0, 4)) + 543)).format('YYYY')
            },
            formatNumber(value) {
                return numeral(value).format("0,0")
            },
            formatPrice(value) {
                return numeral(value).format("0,0.00")
            }
        }
    }
})
.component(Button.name, Button)
.component(HasError.name, HasError)
.component(AlertError.name, AlertError)
.component(AlertErrors.name, AlertErrors)
.component(AlertSuccess.name, AlertSuccess)
.component('DataTable', DataTable)
.component('Datepicker', Datepicker)
.component('multiselect', Multiselect)
.component('editor', editor)
.component('tags-input', VueTagsInput)
.component('pagination', pagination)
.component('HeaderCart', HeaderCart)
.component('QuantityProduct', QuantityProduct)
.component('MainMenu', MainMenu)
.component('NotFound', NotFound)
.use(router).mount("#wrapper");
