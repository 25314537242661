<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Orders Management</h3>
                        <small>Edit and manage order permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <form role="form" @submit.prevent="updateOrder">
                    <div class="card">
                        <div class="header">
                            <h2>ฟอร์มบันทึกข้อมูล</h2>
                        </div>
                        <div class="body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="id">เลขที่ใบสั่งซื้อสินค้า</label>
                                                <div class="input-group">
                                                    <input v-model="form.id" type="text" class="form-control" id="id" name="id" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>

                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>สินค้า</th>
                                                            <th class="text-right">ราคาสินค้า</th>
                                                            <th class="text-right">จำนวน</th>
                                                            <th class="text-right">รวม</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(cart, index) in carts" :key="cart.id">
                                                            <td scope="row">{{ (index+1) }}</td>
                                                            <td>
                                                                {{ cart.productName }}<br>
                                                                <span v-if="JSON.parse(cart.productOption).length">
                                                                    <small>
                                                                        <span v-for="(option, o) in JSON.parse(cart.productOption)" :key="option.title">{{ option.title }}: {{ option.value }}<span v-if="JSON.parse(cart.productOption).length != (o + 1)">, </span></span>
                                                                    </small>
                                                                </span>
                                                            </td>
                                                            <td class="text-right">
                                                                <span v-if="cart.priceSpecial" class=""><s>({{ $filters.formatPrice(cart.price) }})</s> {{ $filters.formatPrice(cart.priceSpecial) }}</span>
                                                                <span v-if="!cart.priceSpecial" class="">{{ $filters.formatPrice(cart.price) }}</span>
                                                            </td>
                                                            <td class="text-right">{{ cart.quantity }}</td>
                                                            <td class="text-right">
                                                                <span v-if="cart.priceSpecial" class="">{{ $filters.formatPrice(cart.quantity * cart.priceSpecial) }}</span>
                                                                <span v-if="!cart.priceSpecial" class="">{{ $filters.formatPrice(cart.quantity * cart.price) }}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">ราคารวมสินค้า</td>
                                                            <td class="text-right">{{ $filters.formatPrice(form.totalPrice) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">รหัสส่วนลด</td>
                                                            <td class="text-right">{{ (form.couponCode) ? form.couponCode : "-" }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">ราคาส่วนลด</td>
                                                            <td class="text-right">{{ $filters.formatPrice(form.totalDiscount) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" class="text-right">ราคาทั้งหมด</td>
                                                            <td class="text-right">{{ $filters.formatPrice((form.totalPrice - form.totalDiscount)) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="created_at">วันที่ทำการสั่งซื้อสินค้า</label>
                                                <div class="input-group">
                                                    <input v-model="form.created_at" type="text" class="form-control" id="created_at" name="created_at" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="paystatus">ข้อมูลสถานะการชำระเงิน</label>
                                                <div class="input-group">
                                                    <multiselect v-model="form.paystatus" v-bind="paystatuss"></multiselect>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="shipstatus">ข้อมูลสถานะการจัดส่งสินค้า</label>
                                                <div class="input-group">
                                                    <multiselect v-model="form.shipstatus" v-bind="shipstatuss"></multiselect>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="parceltrack">รูปแบบของการจัดส่ง</label>
                                                <div class="input-group">
                                                    <multiselect v-model="form.parceltrack" v-bind="parceltracks"></multiselect>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="parceltrackNumber">เลขที่การจัดส่งสินค้า</label>
                                                <div class="input-group">
                                                    <input v-model="form.parceltrackNumber" type="text" class="form-control" id="parceltrackNumber" name="parceltrackNumber" aria-describedby="length-name">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                            <router-link :to="{ name: 'order' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <button type="button" class="btn btn-success" @click="downloadPDF(form.id)"><i class="fa-regular fa-file-pdf"></i> EXPORT PDF</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="form.paypal_id">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="header">
                                    <h2>ข้อมูลการชำระเงินผ่านระบบ Paypal</h2>
                                </div>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="paypal_id">Paypal ID</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_id" type="text" class="form-control" id="paypal_id" name="paypal_id" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_intent">Paypal Intent</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_intent" type="text" class="form-control" id="paypal_intent" name="paypal_intent" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_status">Paypal Status</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_status" type="text" class="form-control" id="paypal_status" name="paypal_status" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_create_time">Paypal Create Time</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_create_time" type="text" class="form-control" id="paypal_create_time" name="paypal_create_time" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_update_time">Paypal Update Time</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_update_time" type="text" class="form-control" id="paypal_update_time" name="paypal_update_time" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="paypal_payer_id">Payer ID</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_payer_id" type="text" class="form-control" id="paypal_payer_id" name="paypal_payer_id" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_payer_name">Payer Name</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_payer_name" type="text" class="form-control" id="paypal_payer_name" name="paypal_payer_name" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="paypal_payer_email">Payer Email</label>
                                                <div class="input-group">
                                                    <input v-model="form.paypal_payer_email" type="text" class="form-control" id="paypal_payer_email" name="paypal_payer_email" aria-describedby="length-name" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="header">
                                    <h2>ข้อมูลจัดส่งใบเสร็จรับเงิน</h2>
                                </div>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressFName">ชื่อ</label>
                                                        <div class="input-group">
                                                            <input v-model="form.billingAddressFName" type="text" class="form-control" id="billingAddressFName" name="billingAddressFName" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressLName">นามสกุล</label>
                                                        <div class="input-group">
                                                            <input v-model="form.billingAddressLName" type="text" class="form-control" id="billingAddressLName" name="billingAddressLName" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressAddress">ที่อยู่</label>
                                                        <div class="input-group">
                                                            <textarea v-model="form.billingAddressAddress" class="form-control" rows="5" id="billingAddressAddress" name="billingAddressAddress" disabled></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressPostalCode">รหัสไปรษณีย์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.billingAddressPostalCode" type="text" class="form-control" id="billingAddressPostalCode" name="billingAddressPostalCode" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressEmail">อีเมล์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.billingAddressEmail" type="text" class="form-control" id="billingAddressEmail" name="billingAddressEmail" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="billingAddressTelephone">เบอร์โทรศัพท์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.billingAddressTelephone" type="text" class="form-control" id="billingAddressTelephone" name="billingAddressTelephone" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="header">
                                    <h2>ข้อมูลจัดส่งสินค้า</h2>
                                </div>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressFName">ชื่อ</label>
                                                        <div class="input-group">
                                                            <input v-model="form.shippingAddressFName" type="text" class="form-control" id="shippingAddressFName" name="shippingAddressFName" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressLName">นามสกุล</label>
                                                        <div class="input-group">
                                                            <input v-model="form.shippingAddressLName" type="text" class="form-control" id="shippingAddressLName" name="shippingAddressLName" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressAddress">ที่อยู่</label>
                                                        <div class="input-group">
                                                            <textarea v-model="form.shippingAddressAddress" class="form-control" rows="5" id="shippingAddressAddress" name="shippingAddressAddress" disabled></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressPostalCode">รหัสไปรษณีย์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.shippingAddressPostalCode" type="text" class="form-control" id="shippingAddressPostalCode" name="shippingAddressPostalCode" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressEmail">อีเมล์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.shippingAddressEmail" type="text" class="form-control" id="shippingAddressEmail" name="shippingAddressEmail" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="shippingAddressTelephone">เบอร์โทรศัพท์</label>
                                                        <div class="input-group">
                                                            <input v-model="form.shippingAddressTelephone" type="text" class="form-control" id="shippingAddressTelephone" name="shippingAddressTelephone" aria-describedby="length-name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="header">
                                    <h2>หมายเหตุ</h2>
                                </div>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="input-group">
                                                <textarea v-model="form.ect" class="form-control" rows="5" id="ect" name="ect" disabled></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataId: null,
                form: new Form({
                    id: '',
                    billingAddressFName: '',
                    billingAddressLName: '',
                    billingAddressAddress: '',
                    billingAddressPostalCode: '',
                    billingAddressEmail: '',
                    billingAddressTelephone: '',
                    shippingDiffrentAddress: false,
                    shippingAddressFName: '',
                    shippingAddressLName: '',
                    shippingAddressAddress: '',
                    shippingAddressPostalCode: '',
                    shippingAddressEmail: '',
                    shippingAddressTelephone: '',
                    ect: '',
                    badge: '0',
                    couponID: '0',
                    couponTitle: '',
                    couponCode: '0',
                    totalDiscount: '0',
                    totalPrice: '0',
                    totalWeight: '0',
                    paystatus: '',
                    shipstatus: '',
                    parceltrack: '',
                    parceltrackNumber: '',
                    created_at: '',
                    paypal_id: '',
                    paypal_intent: '',
                    paypal_status: '',
                    paypal_create_time: '',
                    paypal_update_time: '',
                    paypal_payer_id: '',
                    paypal_payer_name: '',
                    paypal_payer_email: '',
                }),
                module: 'order',
                max: 255,
                carts: [],
                paystatuss: {
                    closeOnSelect: true,
                    searchable: true,
                    options: []
                },
                shipstatuss: {
                    closeOnSelect: true,
                    searchable: true,
                    options: []
                },
                parceltracks: {
                    closeOnSelect: true,
                    searchable: true,
                    options: []
                },
            }
        },
        mounted() {
            if (this.$gate.isAdmin()) {
                let app = this;
                let id = app.$route.params.id;
                axios.get('/api/order/' + id)
                    .then(function(resp) {
                        app.form.fill(resp.data.dataOrder);
                        app.form.couponTitle = (resp.data.dataCoupon)? resp.data.dataCoupon.title : '';
                        app.carts = resp.data.dataCart;

                        let formatted_created_at = resp.data.dataOrder.created_at;
                        let dateObject = new Date(formatted_created_at);
                        dateObject.setHours(dateObject.getHours() + 7);
                        app.form.created_at = dateObject.toISOString().slice(0, 19).replace('T', ' ');
                    })
                    .catch(function() {
                        app.$router.push({
                            // path: '/SystemControl/Order'
                        });
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            getLocalTime(utcTime) {
                console.log(utcTime);
                // const localDate = parseISO(utcTime);
                // return format(localDate, 'yyyy-MM-dd HH:mm:ss');
            },
            getPaystatus() {
                axios.get('/api/getPaystatus', {
                    params: {

                    }
                }).then(function (response) {
                    this.paystatuss.options = response.data;
                }.bind(this));
            },
            getShipstatus() {
                axios.get('/api/getShipstatus', {
                    params: {

                    }
                }).then(function (response) {
                    this.shipstatuss.options = response.data;
                }.bind(this));
            },
            getParceltrack() {
                axios.get('/api/getParceltrack', {
                    params: {

                    }
                }).then(function (response) {
                    this.parceltracks.options = response.data;
                }.bind(this));
            },
            loadData() {
                if (this.$gate.isAdmin()) {
                    let app = this;
                    axios.get('/api/order/' + app.form.id)
                        .then(function(resp) {
                            app.form.fill(resp.data.dataOrder);
                            app.form.couponTitle = (resp.data.dataCoupon)? resp.data.dataCoupon.title : '';
                            app.carts = resp.data.dataCart;
                        })
                        .catch(function() {
                            app.$router.push({
                                path: '/SystemControl/Order'
                            });
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            updateOrder() {
                var app = this;
                this.form.post('/api/order/' + app.form.id)
                    .then(() => {
                        this.loadData();
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            },
            downloadPDF(id) {
                console.log("Start download for ", id);
                axios.get(`/api/order/print/${id}`, {
                    responseType: "blob",
                }).then((response) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `${id}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }).catch(error => {
                    console.error('Error downloading the PDF:', error);
                    swal.fire('Error', 'Failed to download PDF.', 'error');
                });
            }
        },
        created: function(){
            this.getPaystatus();
            this.getShipstatus();
            this.getParceltrack();
        },
    }
</script>
