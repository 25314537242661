<template>
    <nav id="left-sidebar-nav" class="sidebar-nav">
        <ul id="main-menu" class="metismenu animation-li-delay">
            <li class="header">Manage</li>
            <li :class="{ 'active' : this.$route.name == 'index' || this.$route.name == 'setting'}"><a href="/SystemControl/Setting"><i class="fa fa-gear"></i> <span>ปรับแต่งระบบหลัก</span></a></li>
            <li :class="{ 'active' : this.$route.name == 'users' || this.$route.name == 'createdUsers' || this.$route.name == 'editedUsers'}"><a href="/SystemControl/Users"><i class="fa fa-user"></i> <span>ผู้ใช้งานระบบ</span></a></li>

            <li class="header">App</li>
            <li :class="{ 'active' : this.$route.name == 'slide' || this.$route.name == 'createdSlide' || this.$route.name == 'editedSlide'}"><a href="/SystemControl/Slide"><i class="fa fa-images"></i> <span>ภาพสไลด์</span></a></li>
            <li :class="{ 'active' : this.$route.name == 'productCategory' || this.$route.name == 'createdProductCategory' || this.$route.name == 'editedProductCategory' || this.$route.name == 'product' || this.$route.name == 'createdProduct' || this.$route.name == 'editedProduct'}">
                <a href="#Product" class="has-arrow"><i class="fa fa-boxes-stacked"></i> <span>สินค้าและบริการ</span></a>
                <ul>
                    <li :class="{ 'active' : this.$route.name == 'productCategory' || this.$route.name == 'createdProductCategory' || this.$route.name == 'editedProductCategory'}"><a href="/SystemControl/ProductCategory">หมวดหมู่สินค้า</a></li>
                    <li :class="{ 'active' : this.$route.name == 'product' || this.$route.name == 'createdProduct' || this.$route.name == 'editedProduct'}"><a href="/SystemControl/Product">ข้อมูลสินค้า</a></li>
                </ul>
            </li>
            <li :class="{ 'active' : this.$route.name == 'order' || this.$route.name == 'editedOrder' || this.$route.name == 'paystatus' || this.$route.name == 'createdPaystatus' || this.$route.name == 'editedPaystatus' || this.$route.name == 'shipstatus' || this.$route.name == 'createdShipstatus' || this.$route.name == 'editedShipstatus' || this.$route.name == 'parceltrack' || this.$route.name == 'createdParceltrack' || this.$route.name == 'editedParceltrack'}">
                <a href="#Order" class="has-arrow"><i class="fa fa-cart-shopping"></i> <span>ข้อมูลการสั่งซื้อสินค้า</span></a>
                <ul>
                    <li :class="{ 'active' : this.$route.name == 'order' || this.$route.name == 'editedOrder'}"><a href="/SystemControl/Order">รายการสั่งซื้อสินค้า</a></li>
                    <li :class="{ 'active' : this.$route.name == 'paystatus' || this.$route.name == 'createdPaystatus' || this.$route.name == 'editedPaystatus'}"><a href="/SystemControl/Paystatus">ข้อมูลสถานะการชำระเงิน</a></li>
                    <li :class="{ 'active' : this.$route.name == 'shipstatus' || this.$route.name == 'createdShipstatus' || this.$route.name == 'editedShipstatus'}"><a href="/SystemControl/Shipstatus">ข้อมูลสถานะการจัดส่งสินค้า</a></li>
                    <li :class="{ 'active' : this.$route.name == 'parceltrack' || this.$route.name == 'createdParceltrack' || this.$route.name == 'editedParceltrack'}"><a href="/SystemControl/Parceltrack">ข้อมูลรูปแบบการจัดส่ง</a></li>
                </ul>
            </li>
            <li :class="{ 'active' : this.$route.name == 'coupon' || this.$route.name == 'createdCoupon' || this.$route.name == 'editedCoupon'}"><a href="/SystemControl/Coupon"><i class="fa fa-tags"></i> <span>คูปองส่วนลด</span></a></li>
            <li :class="{ 'active' : this.$route.name == 'contact' || this.$route.name == 'createdContact' || this.$route.name == 'editedContact'}"><a href="/SystemControl/Contact"><i class="fa fa-phone-volume"></i> <span>ติดต่อสอบถาม</span></a></li>
        </ul>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                countUsers: 0
            }
        },
        methods: {
            getCountUsers: function() {
                axios.get('/api/getCountUsers',{
                    params: {
                        
                    }
                }).then(function(response){
                    this.countUsers = response.data;
                }.bind(this));
            }
        },
        created: function() {
            // this.getCountUsers();
        }
    }
</script>
