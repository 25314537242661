<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Product Category Management</h3>
                        <small>Create, Edit and manage product category permissions</small>
                    </div>
                    <div>
                        <div class="btn-group mb-3">
                            <router-link :to="{name: 'createdProductCategory'}"><button type="button" class="btn btn-info mb-2"><i class="fa-solid fa-plus"></i> <span>Create New Product Category</span></button></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-12">
                <div class="card">
                    <div class="body">
                        <form role="form" @submit.prevent="searchedProductCategory()">
                            <div class="row">
                                <div class="col-sm-12 pb-2">
                                    <div class="input-group">
                                        <input v-model="form.title" type="text" class="form-control" placeholder="ค้นหาข้อมูล" aria-label="ค้นหาข้อมูล" aria-describedby="search-data" id="title" name="title">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-primary btn-lg btn-block" type="submit"><i class="fa-solid fa-magnifying-glass"></i> Search</button>
                                      </div>
                                </div>
                            </div>
                        </form>

                        <!--PC-->
                        <div class="row d-none d-sm-block">
                            <div class="col-sm-12">
                                <div class="table-content mt-2">
                                    <DataTable 
                                        :headers="headers"
                                        :items="items"
                                        :rows-per-page="50"
                                        show-index
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>
            
                                        <!--Header-->
                                        <template #header-title="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-status="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-sort="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-manage="header">
                                            <div class="header-text text-center manage">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-title="{ title }">
                                            <div class="text-left">{{ title }}</div>
                                        </template>
                                        <template #item-status="{ id, publish }">
                                            <div class="text-center">
                                                <span class="badge badge-success btnStatus" v-if="publish == 1" @click="unPublishProductCategory(id)">Publish</span>
                                                <span class="badge badge-warning btnStatus" v-if="publish == 0" @click="publishProductCategory(id)">UnPublish</span>
                                            </div>
                                        </template>
                                        <template #item-sort="{ sort }">
                                            <div class="text-center">
                                                {{ sort }}
                                            </div>
                                        </template>
                                        <template #item-manage="{ id, publish }">
                                            <div class="text-center">
                                                <router-link :to="{name: 'editedProductCategory', params: {id: id}}">
                                                    <button type="button" class="btn btnEdit" title="Edit"><i class="fa-regular fa-pen-to-square"></i></button>
                                                </router-link>

                                                <button type="button" class="btn btnDelete" @click="deletedProductCategory(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>

                                                <button type="button" class="btn btnPublish" v-if="publish == '1'" @click="unPublishProductCategory(id)" title="Unpublish"><i class="fa-solid fa-eye-slash"></i></button>
                                                <button type="button" class="btn btnPublish" v-if="publish == '0'" @click="publishProductCategory(id)" title="Unpublish"><i class="fa-solid fa-eye"></i></button>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                        <!--MOBILE-->
                        <div class="row d-block d-sm-none">
                            <div class="col-sm-12">
                                <div class="table-content mt-2">
                                    <DataTable 
                                        ref="dataTableMobile" 
                                        :headers="headerMobiles"
                                        :items="items"
                                        :rows-per-page="20"
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>

                                        <!--Header-->
                                        <template #header-title="header">
                                            <div class="header-text text-center">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-title="{ id, title, publish, sort }">
                                            <div class="dataMobile">
                                                <label for="title">Title:</label>
                                                <p class="mb-0">
                                                    <span v-if="!title">-</span>
                                                    <span v-if="title">{{ title }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="status">Status:</label>
                                                <p class="mb-0">
                                                    <span class="badge badge-success btnStatus mr-0" v-if="publish == 1" @click="unPublishProductCategory(id)">Publish</span>
                                                    <span class="badge badge-warning btnStatus mr-0" v-if="publish == 0" @click="publishProductCategory(id)">UnPublish</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="sort">Sort:</label>
                                                <p class="mb-0">
                                                    <span v-if="!sort">-</span>
                                                    <span v-if="sort">{{ sort }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="manage">Manage:</label>
                                                <p class="mb-0">
                                                    <router-link :to="{name: 'editedProductCategory', params: {id: id}}">
                                                        <button type="button" class="btn btnEdit" title="Edit"><i class="fa-regular fa-pen-to-square"></i></button>
                                                    </router-link>

                                                    <button type="button" class="btn btnDelete" @click="deletedProductCategory(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>

                                                    <button type="button" class="btn btnPublish" v-if="publish == '1'" @click="unPublishProductCategory(id)" title="Unpublish"><i class="fa-solid fa-eye-slash"></i></button>
                                                    <button type="button" class="btn btnPublish" v-if="publish == '0'" @click="publishProductCategory(id)" title="Unpublish"><i class="fa-solid fa-eye"></i></button>
                                                </p>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            if(this.$gate.isAdmin()) {
                return {
                    form: new Form({
                        title: '',
                    }),
                    isSearch: false,
                    headers: [
                        { text: "TITLE", value: "title", sortable: true, width: 'auto' },
                        { text: "STATUS", value: "status", sortable: true, width: 150 },
                        { text: "SORT", value: "sort", sortable: true, width: 100 },
                        { text: "MANAGE", value: "manage", fixed: true, width: 160 },
                    ],
                    headerMobiles: [
                        { text: "TITLE", value: "title", sortable: true, width: 'auto' },
                    ],
                    items: [],
                }
            } else {
                return {}
            }
        },
        created() {
            this.loadProductCategorys();
        },
        methods: {
            searchedProductCategory() {
                if(this.$gate.isAdmin()) {
                    this.isSearch = true;
                    let queryTitle = this.form.title;
                    axios.get('/api/productCategory/findProductCategory?title=' + queryTitle)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            getResults(page = 1) {
                if(this.$gate.isAdmin()) {
                    if(this.isSearch === true) {
                        let queryTitle = this.form.title;
                        axios.get('/api/productCategory/findProductCategory?title=' + queryTitle + '&page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    } else {
                        axios.get('/api/productCategory?page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    }
                }
            },
            loadProductCategorys() {
                if(this.$gate.isAdmin()) {
                    axios.get('/api/productCategory')
                    .then(response => {
                        this.items = response.data;
                    })
                    .catch(function () {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
                }
            },
            publishProductCategory(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการเปิดข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'เปิดการเผยแพร่ข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/productCategory/publish/'+id).then(()=>{
                                swal.fire(
                                    'Publish!',
                                    'เปิดการเผยแพร่ข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadProductCategorys();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
            unPublishProductCategory(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการปิดข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ปิดการเผยแพร่ข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/productCategory/unPublish/'+id).then(()=>{
                                swal.fire(
                                    'Publish!',
                                    'ปิดการเผยแพร่ข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadProductCategorys();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
            deletedProductCategory(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ลบข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/productCategory/delete/'+id).then(()=>{
                                swal.fire(
                                    'Deleted!',
                                    'ลบข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadProductCategorys();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
        }
    }
</script>
