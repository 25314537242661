<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>User Management</h3>
                        <small>Create, Edit and manage users permissions</small>
                    </div>
                    <div>
                        <div class="btn-group mb-3">
                            <router-link :to="{name: 'createdUsers'}"><button type="button" class="btn btn-info mb-2"><i class="fa-solid fa-plus"></i> <span>Create New User</span></button></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-12">
                <div class="card">
                    <div class="body">
                        <form role="form" @submit.prevent="searchedUsers()">
                            <div class="row">
                                <div class="col-sm-12 pb-2">
                                    <div class="input-group">
                                        <input v-model="form.name" type="text" class="form-control" placeholder="ค้นหาข้อมูล" aria-label="ค้นหาข้อมูล" aria-describedby="search-data" id="name" name="name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-primary btn-lg btn-block" type="submit"><i class="fa-solid fa-magnifying-glass"></i> Search</button>
                                      </div>
                                </div>
                            </div>
                        </form>

                        <!--PC-->
                        <div class="row d-none d-sm-block">
                            <div class="col-sm-12">
                                <div class="table-content mt-2">
                                    <DataTable 
                                        :headers="headers"
                                        :items="items"
                                        :rows-per-page="50"
                                        show-index
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>
            
                                        <!--Header-->
                                        <template #header-user="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-image="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-email="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-status="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-sort="header">
                                            <div class="header-text text-center">
                                                {{ header.text }}
                                            </div>
                                        </template>
                                        <template #header-manage="header">
                                            <div class="header-text text-center manage">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-user="{ name, last_name, role }">
                                            <div class="text-left">
                                                <a href="#" title="">{{ name }} {{ last_name }}</a><br>
                                                Role: {{ $filters.upTextAll(role) }}
                                            </div>
                                        </template>
                                        <template #item-image="{ image, name, last_name }">
                                            <div class="text-center">
                                                <img v-if="image" :src="'/upload/users/image/' + image" data-toggle="tooltip"
                                                    data-placement="top" :title="name + ' ' + last_name" :alt="name + ' ' + last_name"
                                                    class="w50 rounded">
                                            </div>
                                        </template>
                                        <template #item-email="{ email }">
                                            <div class="text-left">
                                                {{ email }}
                                            </div>
                                        </template>
                                        <template #item-status="{ id, publish }">
                                            <div class="text-center">
                                                <span class="badge badge-success btnStatus" v-if="publish == 1" @click="unPublishUsers(id)">Publish</span>
                                                <span class="badge badge-warning btnStatus" v-if="publish == 0" @click="publishUsers(id)">UnPublish</span>
                                            </div>
                                        </template>
                                        <template #item-sort="{ sort }">
                                            <div class="text-center">
                                                {{ sort }}
                                            </div>
                                        </template>
                                        <template #item-manage="{ id, publish }">
                                            <div class="text-center">
                                                <router-link :to="{name: 'editedUsers', params: {id: id}}">
                                                    <button type="button" class="btn btnEdit" title="Edit"><i class="fa-regular fa-pen-to-square"></i></button>
                                                </router-link>

                                                <button type="button" class="btn btnDelete" @click="deletedUsers(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>

                                                <button type="button" class="btn btnPublish" v-if="publish == '1'" @click="unPublishUsers(id)" title="Unpublish"><i class="fa-solid fa-eye-slash"></i></button>
                                                <button type="button" class="btn btnPublish" v-if="publish == '0'" @click="publishUsers(id)" title="Unpublish"><i class="fa-solid fa-eye"></i></button>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                        <!--MOBILE-->
                        <div class="row d-block d-sm-none">
                            <div class="col-sm-12">
                                <div class="table-content mt-2">
                                    <DataTable 
                                        ref="dataTableMobile" 
                                        :headers="headerMobiles"
                                        :items="items"
                                        :rows-per-page="20"
                                        header-text-direction="center"
                                        body-text-direction="center"
                                        buttons-pagination>

                                        <!--Header-->
                                        <template #header-user="header">
                                            <div class="header-text text-center">
                                                {{ header.text}}
                                            </div>
                                        </template>
                                        <!--Header-->
                                        <!--Item-->
                                        <template #item-user="{ id, name, last_name, role, image, email, publish, sort }">
                                            <div class="dataMobile">
                                                <label for="user">User:</label>
                                                <p class="mb-0">
                                                    <span v-if="!name">-</span>
                                                    <span v-if="name">{{ name }} {{ last_name }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="role">Role:</label>
                                                <p class="mb-0">
                                                    <span v-if="!role">-</span>
                                                    <span v-if="role">{{ $filters.upTextAll(role) }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="image">Image:</label>
                                                <p class="mb-0">
                                                    <span v-if="!image">-</span>
                                                    <span v-if="image"><img :src="'/upload/users/image/' + image" width="50"></span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="user">Email:</label>
                                                <p class="mb-0">
                                                    <span v-if="!email">-</span>
                                                    <span v-if="email">{{ email }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="status">Status:</label>
                                                <p class="mb-0">
                                                    <span class="badge badge-success btnStatus mr-0" v-if="publish == 1" @click="unPublishUsers(id)">Publish</span>
                                                    <span class="badge badge-warning btnStatus mr-0" v-if="publish == 0" @click="publishUsers(id)">UnPublish</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="sort">Sort:</label>
                                                <p class="mb-0">
                                                    <span v-if="!sort">-</span>
                                                    <span v-if="sort">{{ sort }}</span>
                                                </p>
                                            </div>
                                            <div class="dataMobile">
                                                <label for="manage">Manage:</label>
                                                <p class="mb-0">
                                                    <router-link :to="{name: 'editedUsers', params: {id: id}}">
                                                        <button type="button" class="btn btnEdit" title="Edit"><i class="fa-regular fa-pen-to-square"></i></button>
                                                    </router-link>

                                                    <button type="button" class="btn btnDelete" @click="deletedUsers(id)" title="Trash"><i class="fa-regular fa-trash-can"></i></button>

                                                    <button type="button" class="btn btnPublish" v-if="publish == '1'" @click="unPublishUsers(id)" title="Unpublish"><i class="fa-solid fa-eye-slash"></i></button>
                                                    <button type="button" class="btn btnPublish" v-if="publish == '0'" @click="publishUsers(id)" title="Unpublish"><i class="fa-solid fa-eye"></i></button>
                                                </p>
                                            </div>
                                        </template>
                                        <!--Item-->
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            if(this.$gate.isAdmin()) {
                return {
                    form: new Form({
                        name: '',
                    }),
                    isSearch: false,
                    headers: [
                        { text: "USER", value: "user", sortable: true, width: 'auto' },
                        { text: "IMAGE", value: "image", width: 100 },
                        { text: "EMAIL", value: "email", sortable: true, width: 250 },
                        { text: "STATUS", value: "status", sortable: true, width: 150 },
                        { text: "SORT", value: "sort", sortable: true, width: 100 },
                        { text: "MANAGE", value: "manage", fixed: true, width: 160 },
                    ],
                    headerMobiles: [
                        { text: "USER", value: "user", sortable: true, width: 'auto' },
                    ],
                    items: [],
                }
            } else {
                return {}
            }
        },
        created() {
            this.loadUserss();
        },
        methods: {
            searchedUsers() {
                if(this.$gate.isAdmin()) {
                    this.isSearch = true;
                    let queryName = this.form.name;
                    axios.get('/api/users/findUsers?name=' + queryName)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            getResults(page = 1) {
                if(this.$gate.isAdmin()) {
                    if(this.isSearch === true) {
                        let queryName = this.form.name;
                        axios.get('/api/users/findUsers?name=' + queryName + '&page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    } else {
                        axios.get('/api/users?page=' + page)
                        .then(response => {
                            this.items = response.data;
                        })
                        .catch(function () {
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                    }
                }
            },
            loadUserss() {
                if(this.$gate.isAdmin()) {
                    axios.get('/api/users')
                    .then(response => {
                        this.items = response.data;
                    })
                    .catch(function () {
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
                }
            },
            publishUsers(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการเปิดข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'เปิดการเผยแพร่ข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/users/publish/'+id).then(()=>{
                                swal.fire(
                                    'Publish!',
                                    'เปิดการเผยแพร่ข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadUserss();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
            unPublishUsers(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการปิดข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ปิดการเผยแพร่ข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/users/unPublish/'+id).then(()=>{
                                swal.fire(
                                    'Publish!',
                                    'ปิดการเผยแพร่ข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadUserss();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
            deletedUsers(id){
                if(this.$gate.isAdmin()) {
                    swal.fire({
                        title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                        text: "หากคุณตอบตกลง คุณจะไม่สามารถยกเลิกสิ่งนี้ได้!",
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'ลบข้อมูล!',
                        cancelButtonText: 'ยกเลิก!',
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                            axios.get('/api/users/delete/'+id).then(()=>{
                                swal.fire(
                                    'Deleted!',
                                    'ลบข้อมูลเรียบร้อยแล้ว',
                                    'success'
                                );
                                this.loadUserss();
                            }).catch(()=> {
                                swal.fire(
                                    'Error!',
                                    'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                                    'error'
                                );
                            });
                        }
                    })
                }
            },
        }
    }
</script>
