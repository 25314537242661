<template>
    <div class="container">
        <div class="row flex-row-reverse">
            <div class="col-lg-12">
                <h2 class="text-center pt-30 pb-30">Your cart items<small>(1.6)</small></h2>
            </div>
        </div>
        <div class="row" v-if="this.$parent.badge != 0">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <form action="#">
                    <div class="table-content table-responsive cart-table-content">
                        <table style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Until Price</th>
                                    <th>Qty</th>
                                    <th>Subtotal</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cart, n) in this.form.carts" :key="n">
                                    <td class="product-thumbnail">
                                        <a :href="'/product/' + cart.titleLink" v-if="cart.image"><img :src="'/upload/product/image/thumbnail/' + cart.image" alt="" width="100"></a>
                                        <a :href="'/product/' + cart.titleLink" v-if="!cart.image"><img :src="'/frontEnd/template/assets/images/noImage.png'" alt="" width="100"></a>
                                    </td>
                                    <td class="product-name text-start">
                                        <a :href="'/product/' + cart.titleLink">{{ cart.title }}</a>
                                        <p v-if="cart.options.length">
                                            <small>
                                                <span v-for="(option, o) in cart.options" :key="option.title">{{ option.title }}: {{ option.value }}<span v-if="cart.options.length != (o + 1)">, </span></span>
                                            </small>
                                        </p>
                                    </td>
                                    <td class="product-price-cart">
                                        <span v-if="cart.priceSpecial != 0 && cart.priceSpecial != null" class="amount"><s>฿{{ $filters.formatPrice(cart.price) }}</s> ฿{{ $filters.formatPrice(cart.priceSpecial) }}</span>
                                        <span v-if="cart.priceSpecial == 0 || cart.priceSpecial == null" class="amount">฿{{ $filters.formatPrice(cart.price) }}</span>
                                    </td>
                                    <td class="product-quantity">
                                        <div class="cart-plus-minus">
                                            <div @click="minusAmount(n)" class="dec qtybutton">-</div>
                                            <input class="cart-plus-minus-box" type="number" name="quantity" v-model="cart.quantity">
                                            <div @click="plusAmount(n)" class="inc qtybutton">+</div>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">
                                        <span v-if="cart.priceSpecial != 0 && cart.priceSpecial != null" class="">฿{{ $filters.formatPrice((cart.quantity * cart.priceSpecial)) }}</span>
                                        <span v-if="cart.priceSpecial == 0 || cart.priceSpecial == null" class="">฿{{ $filters.formatPrice((cart.quantity * cart.price)) }}</span>
                                    </td>
                                    <td class="product-remove">
                                        <a @click="removeCart(n)" href="javascript:;"><i class="fa fa-times"></i></a>
                                   </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="cart-shiping-update-wrapper">
                                <div class="cart-shiping-update">
                                    <a href="/our-products">Continue Shopping</a>
                                </div>
                                <div class="cart-clear">
                                    <button type="button" @click="updateCart()">Update Shopping Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-lg-4 offset-lg-4 col-md-12">
                        <div class="discount-code-wrapper">
                            <div class="title-wrap">
                                <h4 class="cart-bottom-title section-bg-gray">Use Coupon Code</h4>
                            </div>
                            <div class="discount-code">
                                <p>Enter your coupon code if you have one.</p>
                                <form @submit.prevent="addCouponDiscount()">
                                    <input class="mb-0" v-model="form.discount" type="text" @click="hideError('discount'); hideErrorMessage();">
                                    <has-error :form="form" field="discount"></has-error>

                                    <div class="successDiscount" v-if="messageTypeDiscount == 'success'">{{ messageDiscount }}</div>
                                    <div class="errorDiscount" v-if="messageTypeDiscount == 'error'">{{ messageDiscount }}</div>

                                    <input v-model="form.carts" type="hidden">
                                    <button :disabled='isDisabled' class="mt-30 cart-btn-2" :class="{ 'disabled' : isDisabled }" type="submit">Apply Coupon</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="grand-totall">
                            <div class="title-wrap">
                                <h4 class="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                            </div>
                            <h5>Total products <span>฿{{ $filters.formatPrice(this.$parent.totalPrice) }}</span></h5>
                            <h5>Total discount <span>฿{{ $filters.formatPrice(this.$parent.totalDiscount) }}</span></h5>
                            <div class="total-shipping">
                                <h5>Total shipping</h5>
                                <ul>
                                    <li>Free <span>฿0.00</span></li>
                                </ul>
                            </div>
                            <h4 class="grand-totall-title">Grand Total <span>฿{{ $filters.formatPrice((this.$parent.totalPrice - this.$parent.totalDiscount)) }}</span></h4>
                            <a v-if="!isDisabled" href="/checkout">Proceed to Checkout</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <h3 class="text-center">You have no items in your shopping cart.</h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    discount: '',
                    carts: '',
                    totalPrice: '',
                }),
                isDisabled: true,
                messageTypeDiscount: '',
                messageDiscount: '',
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            hideErrorMessage() {
                this.messageTypeDiscount = '';
            },
            minusAmount(n) {
                this.clearCouponDiscount();

                if(this.$parent.carts[n].quantity > 1) {
                    this.$parent.carts[n].quantity = parseInt(this.$parent.carts[n].quantity) - 1;

                    let dataPrice;
                    this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                        dataPrice = ((item.priceSpecial == 0 || item.priceSpecial == null) ? item.price : item.priceSpecial);
                        return total + (item.quantity * dataPrice);
                    }, 0);

                    this.storeCart();
                }
            },
            plusAmount(n) {
                this.clearCouponDiscount();

                this.$parent.carts[n].quantity = parseInt(this.$parent.carts[n].quantity) + 1;
                
                let dataPrice;
                this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                    dataPrice = ((item.priceSpecial == 0 || item.priceSpecial == null) ? item.price : item.priceSpecial);
                    return total + (item.quantity * dataPrice);
                }, 0);

                this.storeCart();
            },
            viewCart() {
                // localStorage.removeItem('carts');
                // localStorage.removeItem('couponID');
                // localStorage.removeItem('couponCode');
                // localStorage.removeItem('totalDiscount');

                if(localStorage.getItem('carts')) {
                    let dataPrice;

                    this.$parent.carts = JSON.parse(localStorage.getItem('carts'));
                    this.$parent.badge = this.$parent.carts.length;
                    this.$parent.totalPrice = this.$parent.carts.reduce((total, item) => {
                        dataPrice = ((item.priceSpecial == 0 || item.priceSpecial == null) ? item.price : item.priceSpecial);
                        return total + (item.quantity * dataPrice);
                    }, 0);
                    this.$parent.couponID = parseInt(localStorage.getItem('couponID'));
                    this.$parent.couponCode = localStorage.getItem('couponCode');
                    this.$parent.totalDiscount = parseFloat(localStorage.getItem('totalDiscount'));


                    for (let i = 0; i < this.$parent.carts.length; i++) {
                        if(this.$parent.carts[i].options.length != 0){
                            // sort by name
                            this.$parent.carts[i].options.sort(function(a, b) {
                                const nameA = a.title.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.title.toUpperCase(); // ignore upper and lowercase

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            });
                        }
                    }

                    this.form.discount = localStorage.getItem('couponCode');
                    this.form.carts = this.$parent.carts;
                    this.form.totalPrice = this.$parent.totalPrice;

                    if(this.$parent.carts.length !=0) {
                        this.isDisabled = false;
                    }
                }
            },
            updateCart() {
                this.clearCouponDiscount();
                this.storeCart();
                toast.fire({
                    icon: 'success',
                    title: 'ปรับปรุงตะกร้าสินค้าเรียบร้อย !!'
                });
            },
            storeCart() {
                let parsed = JSON.stringify(this.$parent.carts);
                localStorage.setItem('carts', parsed);
                this.viewCart();
            },
            removeCart(n) {
                swal.fire({
                    title: 'ต้องการลบข้อมูลใช่หรือไม่ ?',
                    text: "หากคุณตอบตกลง จะไม่สามารถยกเลิกสิ่งนี้ได้!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ลบข้อมูล!',
                    cancelButtonText: 'ยกเลิก!',
                }).then((result) => {

                    if (result.value) {
                        this.$parent.carts.splice(n, 1);
                        this.clearCouponDiscount();
                        this.storeCart();
                    }
                });
            },
            addCouponDiscount() {
                this.hideErrorMessage();
                var app = this;
                this.form.post('/api/couponDiscount')
                    .then(function (response) {

                        localStorage.setItem('couponID', parseInt(response.data.couponID));
                        localStorage.setItem('couponCode', response.data.couponCode);
                        localStorage.setItem('totalDiscount', parseFloat(response.data.discountPrice));
                        localStorage.setItem('signature', response.data.signature);

                        app.form.discount = response.data.couponCode;

                        app.messageTypeDiscount = response.data.messageType;
                        app.messageDiscount = response.data.message;

                        app.viewCart();
                    }).catch(error => {
                        this.clearCouponDiscount();
                        this.form.discount = '';
                        toast.fire({
                            icon: 'error',
                            title: error.response?.data?.message ?? 'Something went wrong. Please check again.',
                            showConfirmButton: false,
                            timer: 9000,
                            timerProgressBar: true,
                        });
                    });
            },
            clearCouponDiscount() {
                localStorage.removeItem('couponID');
                localStorage.removeItem('couponCode');
                localStorage.removeItem('totalDiscount');
                localStorage.removeItem('signature');
            },
        },
        created() {
            this.clearCouponDiscount();
            this.viewCart();
        },
    }
</script>