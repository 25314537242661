<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Product Management</h3>
                        <small>Create, Edit and manage product permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="createProduct">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('category') }" @click="hideError('category')">
                                        <label for="category">หมวดหมู่สินค้า</label>
                                        <div class="input-group">
                                            <multiselect
                                                v-model="form.category"
                                                mode="tags"
                                                :close-on-select="false"
                                                :searchable="true"
                                                :create-option="true"
                                                :options="categorys"
                                            />
                                        </div>
                                        <div v-if="form.errors.has('category')">
                                            <span class="help-block">
                                                <has-error :form="form" field="category"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="form.image">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <img class="img-thumbnail" :src="getImage()" alt="Image" style="width: 300px;">
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="image">ไฟล์รูปภาพ <small class="text-green">ไฟล์รูปภาพนามสกุล JPG / JPEG / GIF / PNG เท่านั้น* (แนะนำ กว้าง 600 px, สูง 800 px)</small></label>
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" id="image" name="image" ref="fileInputImage" class="form-control" @change="uploadImage">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': form.errors.has('title') }">
                                        <label for="title">หัวข้อ <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.title" type="text" class="form-control" id="title" name="title" aria-describedby="length-name" @click="hideError('title')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ (max - form.title.length) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('title')">
                                            <span class="help-block">
                                                <has-error :form="form" field="title"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="tag">Tags (Press "ENTER" to add data "Tags")</label>
                                        <div class="input-group">
                                            <tags-input v-model="form.tag" :tags="form.tags" @tags-changed="newTags=>form.tags=newTags"></tags-input>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="detail">รายละเอียด</label>
                                        <div class="input-group">
                                            <textarea v-model="form.detail" class="form-control" rows="3" id="detail" name="detail"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row" v-for="(dataImage, index) in form.dataImages" :key="index">
                                    <div class="col-md-10">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>ชื่อรูปภาพ</label>
                                                    <input v-model="dataImage.titles" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="image">ไฟล์รูปภาพ <small class="text-green">ไฟล์รูปภาพนามสกุล JPG / JPEG / GIF / PNG เท่านั้น* (แนะนำ กว้าง 600 px, สูง 800 px)</small></label>
                                                    <input type="file" :id="index" :name="index" ref="fileInputImages" class="form-control" @change="uploadImages">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="button" class="btn btn-danger" @click="deleteRowImage(index)"><i class="fa fa-trash"></i> ลบข้อมูลนี้</button>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group" v-if="form.dataImages[index].images">
                                            <img class="img-thumbnail" :src="getImages(index)" alt="Image" style="width: 100%">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button style="margin-bottom: 20px;" type="button" class="btn btn-success" @click="addRowImage">เพิ่มข้อมูลแกลเลอรี่รูปภาพ</button>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="price">ราคาสินค้า</label>
                                        <div class="input-group">
                                            <input v-model="form.price" type="number" class="form-control" id="price" name="price">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="priceSpecial">ราคาสินค้าโปรโมชั่น</label>
                                        <div class="input-group">
                                            <input v-model="form.priceSpecial" type="number" class="form-control" id="priceSpecial" name="priceSpecial">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row" v-for="(dataOption, index) in form.dataOptions" :key="index">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>หัวข้อคุณสมบัติ</label>
                                                    <input v-model="dataOption.title" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>รายละเอียด (Press "ENTER" to add data "รายละเอียด")</label>
                                                    <tags-input v-model="dataOption.option" :tags="dataOption.options" @tags-changed="newTags=>dataOption.options=newTags"></tags-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button type="button" class="btn btn-danger" @click="deleteRowOption(index)"><i class="fa fa-trash"></i> ลบข้อมูลนี้</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button style="margin-bottom: 20px;" type="button" class="btn btn-success" @click="addRowOption">เพิ่มข้อมูลคุณสมบัติ</button>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <label>
                                            <input v-model="form.newCollection" type="checkbox" id="newCollection" name="newCollection">
                                                สินค้า New Collection
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <label>
                                            <input v-model="form.outOfStock" type="checkbox" id="outOfStock" name="outOfStock">
                                                ปิดการขายสินค้า
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="checkbox">
                                            <label>
                                            <input v-model="form.preOrder" type="checkbox" id="preOrder" name="preOrder">
                                                Pre-Order
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="stock">สินค้าในสต๊อก</label>
                                        <div class="input-group">
                                            <input v-model="form.stock" type="number" class="form-control" id="stock" name="stock">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('sort') }">
                                        <label for="sort">ลำดับ</label>
                                        <div class="input-group">
                                            <input v-model="form.sort" type="number" class="form-control" id="sort" name="sort" @click="hideError('sort')">
                                        </div>
                                        <div v-if="form.errors.has('sort')">
                                            <span class="help-block">
                                                <has-error :form="form" field="sort"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                    <router-link :to="{ name: 'product' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: new Form({
                    category: [],
                    title: '',
                    detail: '',
                    detailAll: '',
                    image: '',
                    dataImages: [],
                    dataOptions: [],
                    price: 0,
                    priceSpecial: 0,
                    newCollection: false,
                    outOfStock: false,
                    preOrder: false,
                    stock: 0,
                    tag: '',
                    tags: [],
                    sort: '',
                }),
                modules: 'product',
                max: 255,
                categorys: [],
            }
        },
        methods: {
            addRowImage() {
                this.form.dataImages.push({
                    images: '',
                    titles: '',
                })
            },
            addRowOption() {
                this.form.dataOptions.push({
                    title: '',
                    option: '',
                    options: [],
                })
            },
            deleteRowImage(index) {
                this.form.dataImages.splice(index,1)
            },
            deleteRowOption(index) {
                this.form.dataOptions.splice(index,1)
            },
            hideError(element) {
                this.form.errors.clear(element);
            },
            getProductCategory() {
                axios.get('/api/getProductCategory', {
                    params: {

                    }
                }).then(function (response) {
                    this.categorys = response.data;
                }.bind(this));
            },
            getSortProduct: function() {
                axios.get('/api/getSortProduct',{
                    params: {
                        
                    }
                }).then(function(response){
                    if(response.data == 1) {
                        this.form.sort = 1;
                    } else {
                        this.form.sort = response.data.sort + 1;
                    }
                }.bind(this));
            },
            getImage(){
                if(this.form.image) {
                    let imageImage = this.form.image;
                    return imageImage;
                } else {
                    return null;
                }
            },
            uploadImage(e){
                let file = e.target.files[0];
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if((file['type']!="image/jpg" && file['type']!="image/jpeg" && file['type']!="image/png" && file['type']!="image/gif")){
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputImage = this.$refs.fileInputImage;
                    inputImage.type = 'text';
                    inputImage.type = 'file';

                    this.form.image = "";
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                };
                reader.readAsDataURL(file);
            },
            getImages(index){
                if(this.form.dataImages[index].images) {
                    let imageImages = this.form.dataImages[index].images;
                    return imageImages;
                } else {
                    return null;
                }
            },
            uploadImages(e){
                let file = e.target.files[0];
                let index = e.target.name;
                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if((file['type']!="image/jpg" && file['type']!="image/jpeg" && file['type']!="image/png" && file['type']!="image/gif")){
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'กรุณาตรวจสอบไฟล์ที่อัพโหลด !!',
                    });
                    const inputImages = this.$refs.fileInputImages[index];
                    inputImages.type = 'text';
                    inputImages.type = 'file';

                    this.form.dataImages[index].images = "";
                    return false;
                }
                this.form.dataImages[index].titles = file.name;
                reader.onloadend = (file) => {
                    this.form.dataImages[index].images = reader.result;
                };
                reader.readAsDataURL(file);
            },
            createProduct() {
                var app = this;
                this.form.post('/api/product')
                    .then(() => {
                        app.$router.push({
                            path: '/SystemControl/Product'
                        });
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        created: function() {
            this.getProductCategory();
            this.getSortProduct();
        }
    }
</script>
