<template>
    <div class="container-fluid" v-if="$gate.isAdmin()">

        <!-- Page header section  -->
        <div class="row clearfix">
            <div class="col-12">
                <div class="section_title">
                    <div class="mr-3">
                        <h3>Coupon Management</h3>
                        <small>Create, Edit and manage coupon permissions</small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <div class="header">
                        <h2>ฟอร์มบันทึกข้อมูล</h2>
                    </div>
                    <div class="body">
                        <form role="form" @submit.prevent="updateCoupon">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" :class="{ 'has-error': form.errors.has('category') }" @click="hideError('category')">
                                        <label for="category">หมวดหมู่สินค้า</label>
                                        <div class="input-group">
                                            <multiselect
                                                v-model="form.category"
                                                mode="tags"
                                                :close-on-select="false"
                                                :searchable="true"
                                                :create-option="true"
                                                :options="categorys"
                                            />
                                        </div>
                                        <div v-if="form.errors.has('category')">
                                            <span class="help-block">
                                                <has-error :form="form" field="category"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': form.errors.has('title') }">
                                        <label for="title">หัวข้อ <small class="text-green">(ไม่สามารถมี &amp; ? \ / + &lt; &gt; ' " หรือ : ในชื่อได้)</small></label>
                                        <div class="input-group">
                                            <input v-model="form.title" type="text" class="form-control" id="title" name="title" aria-describedby="length-name" @click="hideError('title')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ (max - form.title.length) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('title')">
                                            <span class="help-block">
                                                <has-error :form="form" field="title"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': form.errors.has('code') }">
                                        <label for="code">รหัสส่วนลด</label>
                                        <div class="input-group">
                                            <input v-model="form.code" type="text" class="form-control" id="code" name="code" aria-describedby="length-name" @click="hideError('code')">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="length-name">{{ (max - form.code.length) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="form.errors.has('code')">
                                            <span class="help-block">
                                                <has-error :form="form" field="code"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': form.errors.has('couponDate') }">
                                        <label for="couponDate">กำหนดวันใช้งานคูปองส่วนลด</label>
                                        <div class="input-group">
                                            <Datepicker v-model="form.couponDate" :format="'dd/MM/yyyy'" :enable-time-picker="false" @open="hideError('couponDate')" />
                                        </div>
                                        <div v-if="form.errors.has('couponDate')">
                                            <span class="help-block">
                                                <has-error :form="form" field="couponDate"></has-error>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="detail">รายละเอียด</label>
                                        <div class="input-group">
                                            <textarea v-model="form.detail" class="form-control" rows="3" id="detail" name="detail"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="discountPercent">ส่วนลดคิดเป็น % (หากมีข้อมูล 2 ส่วนจะนำส่วนนี้ไปคิดราคาส่วนลด)</label>
                                        <div class="input-group">
                                            <input v-model="form.discountPercent" type="number" class="form-control" id="discountPercent" name="discountPercent">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="discountPrice">ส่วนลดคิดเป็นราคา (บาท)</label>
                                        <div class="input-group">
                                            <input v-model="form.discountPrice" type="number" class="form-control" id="discountPrice" name="discountPrice">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="minimum">จำนวนขั้นต่ำในการสั่งซื้อ (0 = ไม่มี)</label>
                                        <div class="input-group">
                                            <input v-model="form.minimum" type="number" class="form-control" id="minimum" name="minimum">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="amount">จำนวนคูปองส่วนลด</label>
                                        <div class="input-group">
                                            <input v-model="form.amount" type="number" class="form-control" id="amount" name="amount">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"
                                        :class="{ 'has-error': form.errors.has('sort') }">
                                        <label for="sort">ลำดับ</label>
                                        <div class="input-group">
                                            <input v-model="form.sort" type="number" class="form-control" id="sort" name="sort" @click="hideError('sort')">
                                        </div>
                                        <div v-if="form.errors.has('sort')">
                                            <span class="help-block">
                                                <has-error :form="form" field="sort"></has-error>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-info mr-2"><i class="fa-solid fa-plus"></i> บันทึกข้อมูล</button>
                                    <router-link :to="{ name: 'coupon' }"><button type="button" class="btn btn-warning"><i class="fa-solid fa-rotate-left"></i> ยกเลิกบันทึกข้อมูล</button></router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="container-fluid" v-if="!$gate.isAdmin()">
        <div class="row">
            <div class="col-xs-12">
                <not-found></not-found>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataId: null,
                form: new Form({
                    id: '',
                    category: [],
                    title: '',
                    code: '',
                    couponDate: '',
                    detail: '',
                    discountPercent: 0,
                    discountPrice: 0,
                    minimum: 0,
                    amount: 0,
                    sort: '',
                }),
                module: 'coupon',
                max: 255,
                categorys: [],
            }
        },
        mounted() {
            if (this.$gate.isAdmin()) {
                let app = this;
                let id = app.$route.params.id;
                axios.get('/api/coupon/' + id)
                    .then(function(resp) {
                        app.form.fill(resp.data.dataCoupon);
                        app.form.category = resp.data.dataCouponCategorys;
                    })
                    .catch(function() {
                        app.$router.push({
                            path: '/SystemControl/Coupon'
                        });
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        methods: {
            hideError(element) {
                this.form.errors.clear(element);
            },
            getProductCategory() {
                axios.get('/api/getProductCategory', {
                    params: {

                    }
                }).then(function (response) {
                    this.categorys = response.data;
                }.bind(this));
            },
            loadData() {
                if (this.$gate.isAdmin()) {
                    let app = this;
                    axios.get('/api/coupon/' + app.form.id)
                        .then(function(resp) {
                            app.form.fill(resp.data.dataCoupon);
                            app.form.category = resp.data.dataCouponCategorys;
                        })
                        .catch(function() {
                            app.$router.push({
                                path: '/SystemControl/Coupon'
                            });
                            swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'ไม่สามารถโหลดข้อมูลได้ กรุณาตรวจสอบ !!',
                            });
                        });
                }
            },
            updateCoupon() {
                var app = this;
                this.form.post('/api/coupon/' + app.form.id)
                    .then(() => {
                        this.loadData();
                        swal.fire({
                            icon: 'success',
                            title: 'Success...',
                            text: 'บันทึกข้อมูลเรียบร้อยแล้ว !!',
                        });
                    }).catch(() => {
                        swal.fire({
                            icon: 'error',
                            title: 'Error...',
                            text: 'ทำรายการไม่สำเร็จ กรุณาตรวจสอบ !!',
                        });
                    });
            }
        },
        created: function() {
            this.getProductCategory();
        }
    }
</script>
